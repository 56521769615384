import { Pipe, PipeTransform } from '@angular/core';
import { Event } from '@cx-types/home';
import { convertToTzDate } from '@cx-utils/date';
import {TranslateService} from "@ngx-translate/core";

@Pipe({
  name: 'eventDate',
  pure: false,
})
export class EventDatePipe implements PipeTransform {
  constructor(private translateService: TranslateService) {
  }
  transform(event: {start_date: string, end_date?: string}): string {
    let dateString = '';
    let lang = this.translateService.currentLang;
    if(!lang){
      lang = localStorage.getItem('cx-lang') || 'en'
    }
    if (!!event.end_date && event.start_date !== event.end_date) {
      const startMonthYear = convertToTzDate(event.start_date, 'MM-YYYY', 'dateString',lang);
      const endMonthYear = convertToTzDate(event.end_date, 'MM-YYYY', 'dateString',lang);
      const startYear = convertToTzDate(event.start_date, 'YYYY', 'dateString',lang);
      const endYear = convertToTzDate(event.end_date, 'YYYY', 'dateString',lang);
      if (startYear === endYear) {
        if (startMonthYear === endMonthYear) {
          dateString = `${convertToTzDate(event.start_date, 'D', 'dateString',lang)} - ${convertToTzDate(event.end_date, 'DD MMMM YYYY', 'dateString',lang)}`;
        } else {
          dateString = `${convertToTzDate(event.start_date, 'D MMM', 'dateString',lang)} - ${convertToTzDate(event.end_date, 'DD MMM YYYY', 'dateString',lang)}`;
        }
      } else {
        dateString = `${convertToTzDate(event.start_date, 'D MMM YYYY', 'dateString',lang)} - ${convertToTzDate(event.end_date, 'DD MMM YYYY', 'dateString',lang)}`;
      }
    } else {
      dateString = convertToTzDate(event.start_date, 'D MMMM YYYY', 'dateString',lang);
    }
    return dateString;
  }

}
