import { Component, HostListener, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Event } from '@cx-types/home';

@Component({
  selector: 'cx-event-card',
  templateUrl: './event-card.component.html',
  styleUrls: ['./event-card.component.scss']
})
export class EventCardComponent implements OnInit {

  @Input() item: Event;
  @Input() squareBanner: boolean = false;

  @HostListener('click', ['$event.target'])
  onClick() {
    if(this.item?.cta_link != ""){
      window.location.href = this.item?.cta_link;
    }else{
      this.router.navigate(['/event', this.item.uri]);
    }

  }

  constructor(
    private router: Router
  ) { }

  ngOnInit(): void {
  }

}
