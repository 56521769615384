import { ViewportScroller } from '@angular/common';
import { Component } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'addtix';
  constructor(private router: Router, private viewportScroller: ViewportScroller) {
    // Subscribe to the NavigationEnd event to reset scroll position on navigation
    // this.router.events.subscribe((event) => {
    //   if (event instanceof NavigationEnd) {
    //     // When the NavigationEnd event occurs, reset the scroll position to the top
    //     this.viewportScroller.scrollToPosition([0, 0]);
    //   }
    // });
  }
}
