import { Pipe, PipeTransform } from '@angular/core';
import { convertToTzDate } from '@cx-utils/date';
import {TranslateService} from "@ngx-translate/core";

@Pipe({
  name: 'tz',
  pure: false,
})
export class TzPipe implements PipeTransform {
  constructor(private translateService: TranslateService) {
  }
  transform(
    value: Date | string | number,
    format: string,
    type: 'number' | 'dateString' | 'date' = 'date',
    tz?: string
  ): string {
    const lang = this.translateService.currentLang;
    return convertToTzDate(value, format, type,lang, tz);
  }
}
