import { ChangeDetectorRef, Component, OnInit, inject } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { HomeService } from '@cx-services/home.service';
import { filter } from 'rxjs/operators';
import { Auth, GoogleAuthProvider, signInWithPopup } from '@angular/fire/auth';
import { UserCredential, User } from 'firebase/auth';
import { SECRET_KEY, SESSION_KEY } from '@cx-constants/common';
import { AES, enc } from 'crypto-js';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import {TranslateService} from "@ngx-translate/core";
import {IntegrationService} from "@cx-services/integration.service";

@Component({
  selector: 'cx-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  // private auth: Auth = inject(Auth);
  user: User | null = null;
  loggedIn: boolean;
  mobileSearchActive: boolean = false;
  isRootUrl = false;
  searchQuery: string = '';
  lang: string | null = null;
  private debounceSearchTerm: Subject<string> = new Subject<string>();

  constructor(
    private hs: HomeService,
    // private authService: SocialAuthService,
    private cdr: ChangeDetectorRef,
    private auth: Auth,
    private router: Router,
    private translate: TranslateService,
    public iS: IntegrationService,
  ) {
    // this.translate.getTranslation('')
  }

  ngOnInit(): void {
    const currentLang = localStorage.getItem('cx-lang') || 'en';
    this.translate.use(currentLang);
    this.lang = currentLang;
    this.isRootUrl = this.router.url === '/';
    const sessionItem = localStorage.getItem(SESSION_KEY);
    if (sessionItem) {
      this.user = JSON.parse(AES.decrypt(sessionItem, SECRET_KEY).toString(enc.Utf8));
    }
    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe(() => {
        this.isRootUrl = this.router.url === '/';
        if (this.isRootUrl) {
          this.searchQuery = '';
        }
        this.mobileSearchActive = false;
      });

    // Debounce the method and execute the search logic
    this.debounceSearchTerm.pipe(
      debounceTime(500), // 500ms debounce time
      distinctUntilChanged()
    ).subscribe((debouncedSearchTerm: string) => {
      // Execute the search logic here
      this.hs.updateQuery(debouncedSearchTerm);
    });
  }

  isRoot(): boolean {
    return this.isRootUrl;
  }

  applySearch(event: any) {
    const searchTerm = (event.target as HTMLInputElement).value;
    if(searchTerm.length >= 3){
      this.debounceSearchTerm.next(searchTerm);
    }
    // Push the search term into the debouncer
  }

  clearSearch() {
    this.searchQuery = '';
    this.hs.updateQuery('');
    this.cdr.markForCheck();
  }

  signInWithGoogle(): void {
    // this.authService.signIn(GoogleLoginProvider.PROVIDER_ID);
  }

  get avatarPath() {
    let letter = 'C';
    let avatar = '';
    if (this.user) {
      letter = this.user?.displayName?.[0].toUpperCase() || 'C';
      avatar = `url(${this.user.photoURL}),`;
    }
    return `${avatar}url(https://placehold.co/45x45/7B61FF/FFFFFF?text=${letter})`;
  }

  loginGoogle(){
    const provider = new GoogleAuthProvider();
    provider.addScope('email,profile');
    signInWithPopup(this.auth, provider)
    .then(async (result: UserCredential) => {
      this.user = result.user;
      const encryptedObject = AES.encrypt(JSON.stringify(result.user), SECRET_KEY).toString();
      localStorage.setItem(SESSION_KEY, encryptedObject);
      this.cdr.markForCheck();
    });
  }

  logout(): void {
    this.user = null;
    localStorage.removeItem(SESSION_KEY);
    this.cdr.markForCheck();
  }

  toggleSearch(input?: HTMLInputElement) {
    this.mobileSearchActive = !this.mobileSearchActive;
    if (!this.mobileSearchActive) {
      this.searchQuery = '';
      this.hs.updateQuery('');
    } else {
      setTimeout(() => {
        input?.focus();
      }, 200);
    }
  }

  changeLang(lang: string | null){
    if(this.lang !== lang){
      this.lang = lang;
      this.translate.use(lang || 'en')
      localStorage.setItem('cx-lang',lang || 'en')
    }
  }
}
