<div class="loadingio-spinner-ripple-1qum9hs9evaj">
  <div style="
    position: absolute;
    transform: translate(-50%,50%);
    top: 50%;
    left: 50%;
    font-size: 27px;
" *ngIf="message">{{ message | translate }}</div>
  <div class="ldio-4twvme3jnft">
    <div></div>
    <div></div>
  </div>
</div>
