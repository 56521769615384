<cx-header></cx-header>
<div class="container-xl container-404">
  <img src="/assets/images/404.jpg" alt="">
  <div>
    <h3>Oops!<br/>
      The Page You’re Looking for is Missing</h3>
    <p>We're sorry, but the page you are looking for seems to be missing or has been moved.</p>
  </div>
  <a href="/" class="btn btn-primary">Back to Home Page</a>
</div>
