import { Pipe, PipeTransform } from '@angular/core';
import { environment } from '@cx-env/environment';

@Pipe({
  name: 'asset'
})
export class AssetPipe implements PipeTransform {

  transform(url: string): string {
    return url.includes('http') ? url : environment.assetBaseUrl + url;
  }

}
