import { Directive, EmbeddedViewRef, Input, TemplateRef, ViewContainerRef } from '@angular/core';

@Directive({
  selector: '[cxLet]'
})
export class LetDirective {

  _ref: EmbeddedViewRef<any> | undefined;
  context: any = {};

  @Input()
  set saLet(value: any) {
    // if embeadded view doesn't exist yet create it (only once)
    if (!this._ref) {
      this.createView();
    }

    // if value is empty destroy the component
    // here it's acctualy works like ngIf (will rerender on non-empty value)
    // if (!value && this._ref) {
    //   this._ref.destroy();
    //   this._ref = undefined;

    //   return;
    // }

    if (this._ref) {
      // add the value to the context
      this._ref.context.$implicit = this.context.cxLet = value;
    }
  }

  constructor(private readonly vcRef: ViewContainerRef, private readonly templateRef: TemplateRef<any>) {}

  createView(): void {
    this.vcRef.clear();
    this._ref = this.vcRef.createEmbeddedView(this.templateRef, this.context);
  }

}
