import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { SpinnerComponent } from '@cx-shared/components/spinner/spinner.component';
import { SharedModule } from '@cx-shared/shared.module';
import { BlockUIModule } from 'ng-block-ui';
import * as Sentry from "@sentry/angular";
import { Router } from "@angular/router";
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { environment } from '@cx-env/environment';
import { provideFirebaseApp, initializeApp } from '@angular/fire/app';
import { getAuth, provideAuth } from '@angular/fire/auth';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import {HttpClient} from "@angular/common/http";
import {ToastrModule} from "ngx-toastr";
import * as dayjs from 'dayjs';
import "dayjs/locale/en";
import "dayjs/locale/id";
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http,
    './assets/i18n/',
    '.json');
}

export function getLang(){
  const storageLang = localStorage.getItem('cx-lang');
  if(storageLang){
    dayjs.locale(storageLang);
    return storageLang;
  }else{
    let defaultLang = 'en'
    if(window.location.hostname === 'proliga.addtix.id'){
      defaultLang = 'id';
    }
    dayjs.locale(defaultLang);
    localStorage.setItem('cx-lang', defaultLang);
    return defaultLang
  }
}

const firebaseConfig = environment.firebase;

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    SharedModule,
    BlockUIModule.forRoot({
      template: SpinnerComponent,
    }),
    AppRoutingModule,
    BrowserAnimationsModule,
    NgbModule,
    provideFirebaseApp(() => initializeApp(firebaseConfig)),
    provideAuth(() => getAuth()),
    TranslateModule.forRoot({
      defaultLanguage: getLang(),
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    ToastrModule.forRoot()
  ],
  providers: [
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler(),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    // {
    //   provide: APP_INITIALIZER,
    //   useFactory: () => () => {},
    //   deps: [Sentry.TraceService],
    //   multi: true,
    // },
  ],
  bootstrap: [AppComponent]
})

export class AppModule {
  constructor(trace: Sentry.TraceService) {}
}
