import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'includes',
  pure: false,
})
export class IncludesPipe implements PipeTransform {
  transform(value: any, array: any[]): boolean {
    return array.includes(value);
  }
}
