import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'countdown'
})
export class CountdownPipe implements PipeTransform {
  transform(value: Date | number, now: Date | number): string {
    // const now = new Date().valueOf();
    const difference = +value - +now;

    if (difference <= 0) {
      return 'ended';
    }

    const days = Math.floor(difference / (1000 * 60 * 60 * 24));
    const hours = Math.floor((difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    const minutes = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((difference % (1000 * 60)) / 1000);

    // 3 Day(s) 5 Hour(s) 19 Minute(s) till sale starts!
    return `${days} Day(s) ${hours} Hour(s) ${minutes} Minute(s) till sale starts!`;
  }
}
