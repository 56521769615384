<!-- <div class="container-xl header">
  <div class="header-logo">
    <a href="/">
      <img src="/assets/images/logo.svg" alt="logo"/>
    </a>
  </div>
  <div class="search-container" *ngIf="isRoot()">
    <input class="search-input" [value]="searchQuery" (search)="applySearch($event)" (keyup)="applySearch($event)" placeholder="Search event" type="search" />
    <div class="search-icon">
      <img src="/assets/images/icons/search.svg" alt="">
    </div>
  </div>
  <div class="header-right">
    <div class="header-menu">
      <a class="header-menu-item">Event</a>
      <a class="header-menu-item">FAQ</a>
      <a class="header-menu-item">Create Event</a>
    </div>
    <div class="header-auth">
      <button class="btn btn-outline btn-outline-primary me-3">Login</button>
      <button class="btn btn-primary">Signup</button>
    </div>
  </div>
  <div class="header-mobile">
    <a class="hamburger">
      <img src="/assets/images/icons/hamburger-icon.svg" alt="icon"/>
    </a>
  </div>
</div> -->
<nav class="navbar navbar-expand-lg bg-body-tertiary">
  <div class="container-xl header">
    <a class="navbar-brand" *ngIf="iS.isSukha;else otherLinkLogo" [ngClass]="{'d-none': mobileSearchActive}" >
      <img src="/assets/images/logo.png" alt="logo"/>
    </a>
    <ng-template #otherLinkLogo>
      <a class="navbar-brand" [href]="'/'" [ngClass]="{'d-none': mobileSearchActive}" >
        <img src="/assets/images/logo.png" alt="logo"/>
      </a>
    </ng-template>
    <div *ngIf="isRoot()" class="mobile-search" [ngClass]="{'open': mobileSearchActive}">
      <input #mobileSearch class="search-input" [(ngModel)]="searchQuery" (search)="applySearch($event)" (keyup.enter)="applySearch($event)" [placeholder]="'Search event and enter' | translate" type="text" />
      <div class="clear-search-icon" *ngIf="searchQuery" (click)="clearSearch()">
        <img src="/assets/images/icons/x.svg" alt="">
      </div>
      <div class="search-icon" (click)="toggleSearch(mobileSearch)">
        <img src="/assets/images/icons/search.svg" alt="">
      </div>
    </div>
    <span *ngIf="mobileSearchActive" (click)="toggleSearch()">Cancel</span>
    <button class="navbar-toggler collapsed" *ngIf="!mobileSearchActive && !iS.isSukha" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse" [class.is-source]="iS.isSukha" id="navbarSupportedContent">
      <div class="search-container desktop" *ngIf="isRoot()">
        <input class="search-input" [(ngModel)]="searchQuery" (search)="applySearch($event)" (keyup.enter)="applySearch($event)" [placeholder]="'Search event and enter' | translate" type="text" />
        <div class="clear-search-icon" *ngIf="searchQuery" (click)="clearSearch()">
          <img src="/assets/images/icons/x.svg" alt="">
        </div>
        <div class="search-icon">
          <img src="/assets/images/icons/search.svg" alt="">
        </div>
      </div>
      <div class="header-right">
        <div class="header-menu" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent">
          <ng-container *ngIf="!iS.isSukha && !iS.isOnePiece">
            <a [routerLink]="['/event']" class="header-menu-item">{{ 'Event' | translate}}</a>
            <a [routerLink]="['/faq']" class="header-menu-item">FAQ</a>
          </ng-container>
          <!-- <a class="header-menu-item">{{ 'Create Event' | translate}}</a> -->
        </div>
        <div class="header-lang" *ngIf="lang">
          <div ngbDropdown>
            <a role="button" id="menu-lang" class="menu-lang" ngbDropdownToggle>
              <img [src]="'/assets/images/icons/'+lang+'.png'" alt="flag" />
              <span>{{lang}}</span>
            </a>
            <div ngbDropdownMenu aria-labelledby="menu-lang" [class.dropdown-menu-end]="iS.isSukha">
              <button ngbDropdownItem (click)="changeLang('en')">English</button>
              <button ngbDropdownItem (click)="changeLang('id')">Bahasa Indonesia</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</nav>
