// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  useJson: true,
  jsonBaseUrl: 'https://addtix.oss-ap-southeast-5.aliyuncs.com/dev/',
  apiBaseUrl: 'https://nifty.addtix.id/',
  assetBaseUrl: 'https://addtix.oss-ap-southeast-5.aliyuncs.com/',
  gmapsApiKey: 'AIzaSyBktUWxmngQcdTMq-5HfClTPrEL1_D4lDk',
  xendit_public_key: 'xnd_public_development_dOPYH6NYUwgOJY1sg3JoMTV1Ag7wOb1LX3OyjUrwZxt0HF1Q7juX1py4Tcd1M9id',
  bankTransferAccountNo:'5435-788-855',
  bankTransferAccountName:'PT Reservasi Multi Teknologi',
  firebase: {
    apiKey: "AIzaSyDcaWNhWuzWKckMPc2Fj8G3zeqr3Ju9wF8",
    authDomain: "cognitix-fd040.firebaseapp.com",
    projectId: "cognitix-fd040",
    storageBucket: "cognitix-fd040.appspot.com",
    messagingSenderId: "71263280967",
    appId: "1:71263280967:web:41fb3040f55b7a3a9db662",
    measurementId: "G-6WPGYHFR7S"
  },
  recaptcha: {
    siteKey: '6Ldg14gqAAAAAOZMrmSBDcPSUCij-nNwxc3B_K_G',
    secretKey: '6Ldg14gqAAAAAI1r8Nk0Hv3ImzK4sRaAt4kpsCtV',
  },
  fingerprint:{
    apiKey: 'OLJjYeruo2RP4iZzP3AY',
    region: "ap"
  },
  sentry: {
    dsn: 'https://7c16befc38e9d605145200a8343cf1d3@o4507643538898944.ingest.us.sentry.io/4508039259947008'
  },
  redirect: {
    495: 'https://a2j0k2t24.aaronkwokinjkt.com/',
    // 548: 'https://www.overpassinjakarta2025.com/',
  },
  akId: 495,
  clId: 491,
  klbbBRIId: 545,
};


/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
