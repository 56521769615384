/* eslint-disable */
"use strict";

var collator = new Intl.Collator(undefined, {numeric: true, sensitivity: 'base'});
this.seatMap = undefined;
this.seatMap = {};
var availableColor = '#21E4CF';
var unAvailableColor = '#B5B5B5';
var selectedColor = '#609CEB';

seatMap.zoomStep = 1.1;

seatMap.extend = function(subclass, superclass) {
	"use strict";

	function o() { this.constructor = subclass; }
	o.prototype = superclass.prototype;
	return (subclass.prototype = new o());
};

seatMap.promote = function(subclass, prefix) {
	"use strict";

	var subP = subclass.prototype, supP = (Object.getPrototypeOf&&Object.getPrototypeOf(subP))||subP.__proto__;
	if (supP) {
		subP[(prefix+="_") + "constructor"] = supP.constructor; // constructor is not always innumerable
		for (var n in supP) {
			if (subP.hasOwnProperty(n) && (typeof supP[n] == "function")) { subP[prefix + n] = supP[n]; }
		}
	}
	return subclass;
};

seatMap.indexOf = function (array, searchElement){
	"use strict";

	for (var i = 0,l=array.length; i < l; i++) {
		if (searchElement === array[i]) {
			return i;
		}
	}
	return -1;
};

function ThousandSeparator(n) {
	if (typeof n === 'number') {
        n += '';
        var x = n.split(',');
        var x1 = x[0];
        var x2 = x.length > 1 ? ',' + x[1] : '';
        var rgx = /(\d+)(\d{3})/;
        while (rgx.test(x1)) {
            x1 = x1.replace(rgx, '$1' + '.' + '$2');
        }
        return x1 + x2;
    } else {
        return n;
    }
}

(function(){
	function DisplayingObject(){
		//public
		this.type = "AbstractComponent";
		this.tooltip = [true, true, true, true, true, true, true, true, true, true, true, true, true, true, true, true, true, true, true, true, true, true, true];

		//private
		this._id = -1;
		this._label = "";
		this._layer = null;
		this._angle = 0;
		this._nameLevel = 'Abstract';
	};
	var p = DisplayingObject.prototype;

	p.setId = function(pID){
		this._id = pID;
	}

	p.getId = function(){
		return this._id;
	};

	p.setLabel = function(pLabel){
		this._label = pLabel;
	};

	p.getLabel = function(){
		return this._label;
	};

	p.setAngle = function(pAngle){
		this._angle = pAngle;
	};

	//////////////////

	p.mouseDown = function(ev){ console.log("please redefine '" + ev.type + "' event in " + this.type + " object"); };
	p.mouseMove = function(ev){ console.log("please redefine '" + ev.type + "' event in " + this.type + " object"); };
	p.mouseUp = function(ev){ console.log("please redefine '" + ev.type + "' event in " + this.type + " object"); };
	p.mouseLeave = function(ev){ console.log("please redefine '" + ev.type + "' event in " + this.type + " object"); };

	p.drawing = function(){
		console.log("please redefine 'drawing' method in " + this.type + " object");
	};

	p.bindEvent = function(){
		console.log("please redefine 'bind' method in " + this.type + " object");
	}

	p.destroy = function(){
		console.log("please redefine 'destroy' method in " + this.type + " object");
	};

	p.expressSelected = function(){
		console.log("please redefine 'expressSelected' method in " + this.type + " object");
	}

	p.getasShape = function(){
		console.log("please redefine 'getasShape' method in " + this.type + " object");
	}

	p.selected = function(){
		console.log("please redefine 'selected' method in " + this.type + " object");
	};

	p.released = function(){
		console.log("please redefine 'released' method in " + this.type + " object");
	};

	p.rotating = function(){
		console.log("please redefine 'rotating' method in " + this.type + " object");
	};

	p.rotated = function(){
		console.log("please redefine 'rotated' method in " + this.type + " object");
	};

	p.moving = function(){
		console.log("please redefine 'moving' method in " + this.type + " object");
	};

	p.moved = function(){
		console.log("please redefine 'moved' method in " + this.type + " object");
	};

	p.horizontalFlip = function(){
		console.log("please redefine 'horizontalFlip' method in " + this.type + " object");
	};

	p.verticalFlip = function(){
		console.log("please redefine 'verticalFlip' method in " + this.type + " object");
	};

	p.duplicate = function(){
		console.log("please redefine 'duplicate' method in " + this.type + " object");
	};

	p.delete = function(){
		console.log("please redefine 'delete' method in " + this.type + " object");
	};

	p.getText = function(){
		console.log("please redefine 'getText' method in " + this.type + " object");
	};

	p.getFontSize = function(){
		console.log("please redefine 'getFontSize' method in " + this.type + " object");
	};

	p.getLableColor = function(){
		console.log("please redefine 'getLableColor' method in " + this.type + " object");
	};

	p.getLabelRotation = function(){
		console.log("please redefine 'getLabelRotation' method in " + this.type + " object");
	};

	p.getLabelLeftPosi = function(){
		console.log("please redefine 'getLabelLeftPosi' method in " + this.type + " object");
	};

	p.getLabelTopPosi = function(){
		console.log("please redefine 'getLabelLeftPosi' method in " + this.type + " object");
	};

	p.updatingLabel = function(){
		console.log("please redefine 'updatingLabel' method in " + this.type + " object");
	};

	p.getJSON = function(){
		console.log("please redefine 'getJSON' method in " + this.type + " object");
	};

	p.updateID = function(){
		console.log("please redefine 'updateID' method in " + this.type + " object");
	};

	p.rotate = function(){
		console.log("please redefine 'rotate' method in " + this.type + " object");
	};

	DisplayingObject.makingID = function(){
		return ++DisplayingObject.ID;
	}
	DisplayingObject.ID = 0;

	seatMap.DisplayingObject = DisplayingObject;
}());

(function() {
	"use strict";

	function Point(x, y) {
		this._parent = null;
		this._activePoint = null;
		this._nameLevel = 'ActiveHandler';
		this._isMoving = false;

	 	this.setValues(x, y);

	}
	var p = Point.prototype;


// public methods:
	p.setValues = function(x, y) {
		this.x = this.round(x)||0;
		this.y = this.round(y)||0;
		return this;
	};

	p.copy = function(point) {
		this.x = point.x;
		this.y = point.y;
		return this;
	};

	p.clone = function() {
		return new Point(this.x, this.y);
	};

	p.toString = function() {
		return "[Point (x="+this.x+" y="+this.y+")]";
	};

	p.round = function(val) {
		return Math.round(val);
	};

	p.getDistanceTo = function(pPt){
		var dx = this.x - pPt.x;
		var dy = this.y - pPt.y;
		return Math.sqrt((dx * dx) + (dy * dy));
	};

	p.bindToParent = function(pParent){
		this._parent = pParent;
	};

	p.activity = function(){
		if(!!this._activePoint) this.disactivity();
		this._activePoint = this._parent._layer.paper.circle(this.x, this.y, 5).attr({
			fill: "blue",
			stroke: "red"
		}).addtoLevel(this._nameLevel, this._parent._layer).ScaleWithOriginSize(this._parent._layer);
		this._bindEvent();
	};

	p.disactivity = function(){
		if(this._activePoint){
			this._parent._layer.levels.remove(this._activePoint,this._nameLevel);
		}
	};

	p.rotated = function(pAng,pCenter){
		var CP = seatMap.Point.convertToViewPoint(pCenter.x,pCenter.y,this._parent.getLayer());
		var nx = this.x - CP.x, ny = this.y - CP.y;
		var radi = seatMap.ChartLayer.convertAngToRadi(pAng);
		this.x = this.round(nx * Math.cos(radi) - ny * Math.sin(radi) + CP.x);
		this.y = this.round(nx * Math.sin(radi) + ny * Math.cos(radi) + CP.y);
	};

	p.rotating = function(pAng,pCenter){
		this._activePoint.attr({"transform": "...R"+pAng+","+pCenter.x+","+pCenter.y});
	};

	p.moved = function(pVector){
		this.x += pVector.x;
		this.y += pVector.y;
	};

	p.moving = function(pVect){
		this._activePoint.translate(pVect.x,pVect.y);
	};

	p.scale = function (pScale, pCenter){
		this.x = (this.x-pCenter.x)*pScale + pCenter.x;
		this.y = (this.y-pCenter.y)*pScale + pCenter.y;
	};

	p.getJSON = function(){
		return {"x":this.x, "y":this.y};
	};


	// private method
	p._bindEvent = function(){
		if(!this._activePoint) return;
		var thisPoint = this;
		this._activePoint.mouseover(function(){
			thisPoint._activePoint.attr({cursor: "pointer"});
		}).mousedown(function(ev){
			if(thisPoint._parent._layer.getState() == "selectingObject") ev.stopPropagation();
		}).drag(function(dx,dy){
				if(thisPoint._isMoving || (Math.abs(dx)>seatMap.ChartLayer.stepGrid || Math.abs(dy)>seatMap.ChartLayer.stepGrid)){
					if(!thisPoint._isMoving) thisPoint._isMoving = true;
					var zoom = thisPoint._parent._layer.getZoom();
					var trans_x = dx-this.ox;
					var trans_y = dy-this.oy;
					thisPoint.moved(new seatMap.Point(trans_x/zoom, trans_y/zoom));
					thisPoint._parent.drawing();
					this.translate(trans_x,trans_y);
					this.ox = dx;
					this.oy = dy;
				}
			},function(){
				thisPoint._parent.setMovingPoint(true);
				this.ox = 0;
				this.oy = 0;
			},function(){
				if(thisPoint._isMoving){
					thisPoint._isMoving = false;
					thisPoint._parent.setMovingPoint(false);
					thisPoint._parent.drawing();
				}
			}
		);
	}

	// static method
	Point.makingfromEvent = function(ev){

		return new Point(x,y);
	}
	Point.getPointfromEvent = function(ev,pLayer){
		var x=0,y=0;
		if(ev.pageX){
			x=ev.pageX; y=ev.pageY;
		}else if(ev.originalEvent.pageX){
			x = ev.originalEvent.pageX; y = ev.originalEvent.pageY;
		}else if(ev.originalEvent.changedTouches){
			x = ev.originalEvent.changedTouches[0].pageX; y = ev.originalEvent.changedTouches[0].pageY;
		}
		return seatMap.Point.convertToViewPoint(x,y,pLayer);
	}

	Point.convertToViewPoint = function(pX,pY,pLayer){
		var centerPt = pLayer.getCenter();
		var vx = (pX - centerPt.x)/pLayer.getZoom(), vy = (pY - centerPt.y)/pLayer.getZoom();
		return new Point(centerPt.x+vx,centerPt.y+vy);
	}

	Point.convertToRealPoint = function(px,py,pLayer){
		var centerPt = pLayer.getCenter();
		var vx = (px - centerPt.x)*pLayer.getZoom(), vy = (py - centerPt.y)*pLayer.getZoom();
		return new seatMap.Point(centerPt.x+vx,centerPt.y+vy);
	};

	Point.rotated = function(pX, pY, pAng,pCenter, pLayer){
		var CP = seatMap.Point.convertToViewPoint(pCenter.x,pCenter.y,pLayer);
		var nx = pX - CP.x, ny = pY - CP.y;
		var radi = seatMap.ChartLayer.convertAngToRadi(pAng);
		var rx = Math.round(nx * Math.cos(radi) - ny * Math.sin(radi) + CP.x),
			ry = Math.round(nx * Math.sin(radi) + ny * Math.cos(radi) + CP.y);
		return new seatMap.Point(rx, ry);
	};

	Point.arrangePoints = function (pPt1,pPt2){
		var points = [];
		if(pPt1.x!=pPt2.x){
			points[0] = (pPt1.x<pPt2.x) ? pPt2 : pPt1;
			points[1] = (pPt1.x<pPt2.x) ? pPt1 : pPt2;
		}else{
			points[0] = (pPt1.y<pPt2.y) ? pPt2 : pPt1;
			points[1] = (pPt1.y<pPt2.y) ? pPt1 : pPt2;
		}
		return points;
	}

	Point.equal = function(pt1,pt2){
		return pt1.x == pt2.x && pt1.y == pt2.y;
	}

	Point.zoom = function(pPt, pCenter, pZoom){
		var newPt = {x:0,y:0};
		newPt.x = pCenter.x + (pPt.x-pCenter.x)/pZoom;
		newPt.y = pCenter.y + (pPt.y-pCenter.y)/pZoom;
		return newPt;
	}

	seatMap.Point = Point;
}());

(function(){
	"use strict";

	function Levels(){

		this.levels = [];

		var levelNames = ["Abstract", "Background", "Sections","OutlineLabels","Seats","Shapes","Labels","BoundingArea","ActiveHandler"];

		var privLevel = null,
			thisLevels = this;

		levelNames.forEach(function(CurrNm){
			var currLevel = new seatMap.Level(CurrNm,privLevel);
			thisLevels.levels.push(currLevel);
			privLevel = currLevel;
		});

	};

	var p = Levels.prototype;

	// public methods
	p.initialize = function(){
		for(var i=0;i<this.levels.length;i++){
			this.levels[i].initialize();
		}
	};

	p.insert = function(pObj, pNm){
		for(var i=0;i<this.levels.length;i++){
			var CurrLvl = this.levels[i];
			if(CurrLvl.levelname == pNm){
				CurrLvl.insert(pObj);
				break;
			}
		}
	};

	p.remove = function(pObj,pNm){
		for(var i=0;i<this.levels.length;i++){
			var CurrLvl = this.levels[i];
			if(CurrLvl.levelname == pNm){
				CurrLvl.remove(pObj);
				break;
			}
		}
		pObj.remove();
	}


	// private


	// static

	seatMap.Levels = Levels;
}());

(function(){
	"use strict";

	function Level(name,pPrevlevel){

		this.levelname = name;
		this.objects = [];

		this._prevLevel = pPrevlevel;
	};

	var p = Level.prototype;

	p.insert = function(pObj){
		var topObj = this.objects[this.objects.length-1] || this.getPrevTopObject();
		if(!!topObj) {
			if(pObj.type == "set"){
				for(var i=0;i<pObj.length;i++){
					pObj[i].insertAfter(topObj);
					topObj = pObj[i];
				}
			}else{
				pObj.insertAfter(topObj);
			}
		}else {
			if(pObj.type == "set"){
				for(var i=0;i<pObj.length;i++){
					if(i==0){
						pObj[i].toBack();
						topObj = pObj[i];
					}else{
						pObj[i].insertAfter(topObj);
						topObj = pObj[i];
					}
				}
			}else{
				pObj.toBack();
			}
		}
		if(pObj.type == "set"){
			for(var i=0;i<pObj.length;i++){
				this.objects.push(pObj[i]);
			}
		}else{
			this.objects.push(pObj);
		}
	};

	p.getPrevTopObject = function(){
		if(!this._prevLevel) return null;
		return this._prevLevel.objects[this._prevLevel.objects.length-1] || this._prevLevel.getPrevTopObject();
	};

	p.remove = function(pObj){
		var ind = null;
		if(pObj.type == "set"){
			for(var i=0;i<pObj.length;i++){
				ind = this.objects.indexOf(pObj[i]);
				if (ind >= 0) this.objects.splice(ind, 1);
			}
		}else{
			ind = this.objects.indexOf(pObj);
			if (ind >= 0) this.objects.splice(ind, 1);
		}
	};

	p.initialize = function(){
		this.objects = [];
	};

	seatMap.Level = Level;
}());

(function(){
	"use strict";

	function SettingLabel(){
		this.rowStyle = '0';
		this.rowStart = '';
		this.rowPrefix = '';
		this.rowDir = 0;
		this.seatStyle = '0';
		this.seatStart = '';
		this.seatDir = 'right';
	};

	var p = SettingLabel.prototype;

	p.initialize = function(pKind){
		if(pKind=='seat'){
			this.seatStyle = '0';
			this.seatStart = '';
			this.seatDir = 'right';
			//to remove labels of seats once init this object
		}
		if(pKind == 'row'){
			this.rowStyle = '0';
			this.rowStart = '';
			this.rowPrefix = '';
			this.rowDir = 0;
			//to remove labels of rows once init this object
		}
	}
	p.combinationOption = function(pOpt){
		if(this.rowStyle != pOpt.rowStyle || this.rowStart != pOpt.rowStart || this.rowPrefix!=pOpt.rowPrefix || this.rowDir!=pOpt.rowDir || this.seatStyle!=pOpt.seatStyle || this.seatStart!=pOpt.seatStart || this.seatDir!=pOpt.seatDir){
			this.rowStyle = '-1';
			this.rowStart = '';
			this.rowPrefix = '';
			this.rowDir = 0;
			this.seatStyle = '0';
			this.seatStart = '';
			this.seatDir = 'right';
		}
		return this;
	};
	p.setOptions = function(pOpt){
		for(item in pOpt){
			this[item] = pOpt[item];
		}
	};

	p.clone = function(){
		var instOption = new SettingLabel();
		instOption.rowStyle = this.rowStyle;
		instOption.rowStart = this.rowStart;
		instOption.rowPrefix = this.rowPrefix;
		instOption.rowDir = this.rowDir;
		instOption.seatStyle = this.seatStyle;
		instOption.seatStart = this.seatStart;
		instOption.seatDir = this.seatDir;
		return instOption;
	};

	seatMap.SettingLabel = SettingLabel;
}());

(function(){
	function Categories(pList){
		this.list = pList;
	}

	var p = Categories.prototype;

	p.count = function(){
		return this.list.length;
	}
	p.getEndKey = function(){
		if(this.count()==0) return 0;
		return this.list[this.count()-1].key;
	}
	p.updateCategory = function(pItem){
		for(var i = 0; i < this.count(); i++ ){
			var currCate = this.list[i];
			if(currCate.key == pItem.key){
				currCate.label = pItem.label;
				currCate.color = pItem.color;
				break;
			}
		}
	}
	p.addCategory = function(pCate){
		this.list.push(pCate);
	}
	p.removeCategory = function(pKey){
		for(var i = 0; i < this.count(); i++ ){
			if(this.list[i].key == pKey){
				this.list.splice(i,1);
			}
		}
	}
	p.getCategory = function(pKey){
		if(pKey==-1) return null;
		for(var i = 0; i < this.count(); i++ ){
			if(this.list[i].key == pKey){
				return this.list[i];
			}
		}
		return null;
	}
	p.getJSON = function(){
		return {
			"list" : this.list
		}
	}

	seatMap.Categories = Categories;
}());


(function(){
	"use strict";

	function ChartLayer(pContainerElement, onClick = () => {}, typeCanvas = 'section', baseData = {}, onSeatsUpdate = () => {}){

		/*----- property -----*/

		//public

		this.width = document.querySelector('.booking').clientWidth - 48;
		this.height = 400;
		/* this.width = $(window).width();
		this.height =$(window).height()*0.995; */
		console.log('tay', pContainerElement,  this.width, this.height, document.getElementById(pContainerElement));
		// $("#"+pContainerElement).css("height",this.height);
		this.paper = Raphael(document.getElementById(pContainerElement), this.width, this.height);
		this.typeCanvas = typeCanvas;
		this.canvas = $(this.paper.canvas);
		$("#"+pContainerElement).css("height",this.height);
		this.selectedObjects = new seatMap.SelectedObjects(this);
		this.groupObjects = new seatMap.GroupObjects(this);
		this.levels = new seatMap.Levels();

		this._list =[];
		this.categories = null;
		this.onClickSection = onClick;
		this.onSeatsUpdate = onSeatsUpdate;

		//private
		this._baseData = baseData;
		this._bookedData = {};
		this._isManager = false;
		this._canvascenter = new seatMap.Point(this.width/2, this.height/2);

		this._state = "stateThumb";//"stateBooking";

		this._seatKind = "circle";
		this._seatSize = seatMap.ChartLayer.stepGrid * 2;
		this._seatSeparation = seatMap.ChartLayer.stepGrid * 2;
		this._area = null;
		this._PVw = 0;
		this._PVh = 0;
		this._PVx = 0;
		this._PVy = 0;
		this._zoom = 1;
		this._scaleSection = 1;

		this._ptSt = null;
		this._moveStart = false;
		this._moving = false;
		this._up = false;

		this._groupChildsShape = this.paper.set();

		////labeling
		this._activeObjects = [];

		//// selected section
		this._selSectionID = -1;



		this._initLayer();
	}

	var p = ChartLayer.prototype;

	//public
	p.setState = function(pMode) {//
		this._state = pMode;
		var sections = this.groupObjects.getSections(),
			selObj = this.selectedObjects.getSelectedSection();
		switch(pMode){
			case "stateThumb":
				//$("#load").show();
				$("#back").show();
				window.parent.$("#backThumb").hide();

				$("#InfoBooking").hide();
				selObj.released();
				this._groupChildsShape.show();
				this.canvas.removeAttr('class').attr('class','move');
				this._zoom = 1;
				$("#zoomSlider").slider("value",1);
				this.paper.setViewBox(this._PVx,this._PVy,this._PVw,this._PVh,true);
				this.selectedObjects.releaseSection();
			break;
			case "stateBooking":
				//$("#load").hide();
				$("#back").hide();
				//$("#book").hide();
				window.parent.$("#backThumb").show();
				$(".BGSection").hide();
				this._groupChildsShape.hide();
				sections.forEach(function(d){
					if(d.getId()!=selObj.getId()){
						d.released();
					}
				});
				this.canvas.removeAttr('class').attr('class','move');
				var bound = selObj.getasShape().getBBox(),
					x = bound.x-(this.width-bound.width)/2,
					y = bound.y-(this.height-bound.height)/2,
					zoomPt = new seatMap.Point.zoom(new seatMap.Point(x,y), new seatMap.Point(bound.x+bound.width/2, bound.y + bound.height/2), this._zoom);
				this.paper.setViewBox(zoomPt.x,zoomPt.y,this.width/this._zoom,this.height/this._zoom,true);
			break;
		}
	};
	p.getState = function(){ return this._state; };

	p.getBookedSeats = function(){
		return this.selectedObjects.getBookedSeats();
	};

	p.releaseBookSeats = function(pSeats){
		//this.selectedObjects.releaseBookSeats(pSeats);
		for(var i=0; i<pSeats.length; i++){
			this.selectedObjects.removeBookedSeat(pSeats[i]);
		}
	}
	p.releaseBookStandsSeats = function(pCt, pData){
		this.selectedObjects.removeBookedStandSeats(pCt, pData);
	}
	p.releaseSelectedSeats = function(){
		this._bookedData.bookedOwn = [];
		this.selectedObjects.releaseSelectedSeats();
	};

	p.setBookedState = function(pData){
		for(var SectId in pData.soldOther){
			for(var cateId in pData.soldOther[SectId]){
				if(pData.soldOther[SectId][cateId].hasOwnProperty("seat")){
					this._bookedData.soldOther = this._bookedData.soldOther.concat(pData.soldOther[SectId][cateId].seat);
				}else if(pData.soldOther[SectId][cateId].hasOwnProperty("stand")){
					this._bookedData.soldOtherStand[SectId] = pData.soldOther[SectId];
				}
			}
		}
		for(var SectId in pData.bookedOther){
			for(var cateId in pData.bookedOther[SectId]){
				if(pData.bookedOther[SectId][cateId].hasOwnProperty("seat")){
					this._bookedData.bookedOther = this._bookedData.bookedOther.concat(pData.bookedOther[SectId][cateId].seat);
				}else if(pData.bookedOther[SectId][cateId].hasOwnProperty("stand")){
					this._bookedData.bookedOtherStand[SectId] = pData.bookedOther[SectId];
				}
			}
		}
	};
	p.getBookedStands = function(pSectID){
		return {hold:this._bookedData.bookedOtherStand[pSectID], sold : this._bookedData.soldOtherStand[pSectID]};
	}
	p.getBookingState = function(pID){
		// 0 : available, 1 : sold other, 2 : buy own, 3 : booked Other, 4 : bookedOwn
		if(this._bookedData.soldOwn.indexOf(pID)!=-1) return 2;
		if(this._bookedData.bookedOwn.indexOf(pID)!=-1) return 4;
		if(this._bookedData.bookedOther.indexOf(pID)!=-1) return 3;
		if(this._bookedData.soldOther.indexOf(pID)!=-1) return 1;
		return 0;
	};
	p.successBoughtSeats = function(){
		this.selectedObjects.boughtSeats();
	};
	p.updateBoughtData = function(pSeats){
		if(Array.isArray(pSeats)){
			for(var i=0;i<pSeats.length;i++){
				this._bookedData.soldOwn.push(pSeats[i]);
			}
		}else{

		}
	};
	p.updateBookedData = function(pSeats){
		this._bookedData.bookedOwn = [];
		for(var i=0;i<pSeats.length;i++){
			this._bookedData.bookedOwn.push(pSeats[i]);
		}
		//if(this.selectedObjects.getCtBookedSeats()-this.selectedObjects.getSoldCount()>0){
		if(this._bookedData.bookedOwn.length>0){
			window.parent.$("#book").show();
			//$("#backThumb").hide();
		}else{
			window.parent.$("#book").hide();
			//$("#backThumb").show();
		}
	};
	p.updateBookedStandSeats = function(pQty){
		var bookjson = this.selectedObjects.addBookedStandSeats(pQty);
		this._bookedData.bookedOwnStand[bookjson.key] = bookjson.value;

	}
	p.getCurrAvailStandSeat = function(){
		return this.selectedObjects.getSelectedSection().getAvailStandSeat();
	};
	p.getPriceStand = function(pQtySeats){
		return this.selectedObjects.getSelectedSection().getPriceStand(pQtySeats);
	};
	p.getSelectedSeatsPrice = function(){
		return this.selectedObjects.getSelectedSection().getPrice();
	};

	p.getCenter = function(){ return this._canvascenter; };
	p.getViewBox = function(){
		return this.paper._viewBox;
	};

	p.getSeatSeparation = function(){ return this._seatSeparation; };

	p.setScaleSection = function(pScale){
		//this._scaleSection = pScale;
		//this._seatSize /= pScale;
		//this._zoom *= pScale;
	};
	p.getScaleSection = function(){
		return this._scaleSection;
	};
	p.getSeatSize = function(){ return this._seatSize; };

	p.getSectionQty = function(pSectID){
		this._selSectionID = pSectID;
		if(this._baseData.limitQuantity){
			return this._baseData.quantity[pSectID] || 0;
		}else{
			return "no limit";
		}
	}

	p.setZoom = function(pVal){ this._zoom = pVal;	};
	p.getZoom = function(){	return this._zoom; };
	p.zooming = function(pVal){
		this._zoom = 1 * Math.pow(1.1,pVal);
		this.setViewBox(this.width,this.height);
		const secCon = document.querySelector(`.${this.typeCanvas}-container`);
		const svg = document.querySelector('svg');
		console.log('-')
		if(this._zoom === 1 && secCon.classList.contains('cursor-grabbing')){
			secCon.classList.remove('cursor-grabbing');
		}else if(this._zoom>1 && !secCon.classList.contains('cursor-grabbing')){
			secCon.classList.add('cursor-grabbing');
		}
		if(this._zoom === 1 && svg.classList.contains('cursor-grabbing')){
			svg.classList.remove('cursor-grabbing');
		}else if(this._zoom>1 && !svg.classList.contains('cursor-grabbing')){
			svg.classList.add('cursor-grabbing');
		}
	}

	p.openfromJSON = function(pJSON){
		//this.groupObjects.initialize();
		this.levels.initialize();
		this.paper.clear();
		this._state = "stateThumb";

		$("#InfoBooking").hide();
		this._zoom = 1;
		$("#zoomSlider").slider("value",1);
		this._scaleSection = 1;
		this._seatSize = seatMap.ChartLayer.stepGrid * 2;
		this._seatSeparation = seatMap.ChartLayer.stepGrid * 2;

		var ObjJSON = JSON.parse(pJSON.chartJSON);

		if(!ObjJSON || !ObjJSON.objects) return;

		this._list =JSON.parse(pJSON.category);
		this.categories = new seatMap.Categories(this._list);

		// this._baseData = this._ba);
		this._bookedData = {
			bookedOwn : [],
			soldOwn : [],
			bookedOther : [],
			soldOther : [],
			bookedOwnStand : {},
			soldOwnStand : {},
			bookedOtherStand : {},
			soldOtherStand : {},
		};
		//this._isManager = ObjJSON.manager;
		// this._isManager = this._baseData.userType=='manager';
		this._isManager = false;

		this._moveCenter();
		var area = {
			"width" : ObjJSON.width,
			"height" : ObjJSON.height,
			"x":(this.width/2-ObjJSON.width/2),
			"y":(this.height/2-ObjJSON.height/2)
		};
		this._area = area;

		var thisLayer = this;
		this.setScaleSection(ObjJSON.objects['section'][0].scaleInside);
		for(var kind in ObjJSON.objects){
			switch(kind){
				case "section":
					ObjJSON.objects[kind].forEach(function(objJson){
						console.log(thisLayer,'layur')
						var inst = seatMap[seatMap.ChartLayer.capitalize(kind)].makeInstancefromJSON(objJson,area,thisLayer);
						inst.setId(objJson.id);
						// if(thisLayer.typeCanvas === 'section'){
							inst.drawing(area);
						// }
						thisLayer.groupObjects.addObject(inst);
					});
				break;
				case "background":
					ObjJSON.objects[kind].forEach(function(objJson){
						var inst = thisLayer.paper.image(objJson.url, objJson.posi.x+area.x, objJson.posi.y+area.y, objJson.width , objJson.height).attr({
							transform : "...R"+objJson.angle,
						}).addtoLevel("Background",thisLayer);
					});
				break;
				case "textShape":
					ObjJSON.objects[kind].forEach(function(objJson){
						var textshape =  thisLayer.paper.text(objJson.posi.x+area.x,objJson.posi.y+area.y, objJson.text).attr({
							"font-size" : objJson.size,
							fill : objJson.color,
							transform : "...R"+objJson.angle,
							opacity : 0.8
						}).addtoLevel("Labels",thisLayer);
						thisLayer._groupChildsShape.push(textshape);
					});
				break;
				case "rectShape":
					ObjJSON.objects[kind].forEach(function(pJson){
						var rectshape = thisLayer.paper.rect(pJson.posi.x+area.x-pJson.width/2,pJson.posi.y+area.y-pJson.height/2,pJson.width,pJson.height,pJson.radius).attr({
							"stroke-width" : pJson.strokeWidth,
							stroke : pJson.strokeColor,
							fill : pJson.fillColor,
							transform : "...R"+ pJson.angle,
							opacity : 0.8
						}).addtoLevel("Shapes",thisLayer);
						thisLayer._groupChildsShape.push(rectshape);
					});
				break;
				case "ellipseShape":
					ObjJSON.objects[kind].forEach(function(pJson){
						var ellipsshape = thisLayer.paper.ellipse(pJson.posi.x+area.x,pJson.posi.y+area.y,pJson.width/2,pJson.height/2).attr({
							"stroke-width" : pJson.strokeWidth,
							stroke : pJson.strokeColor,
							fill : pJson.fillColor,
							transform : "...R"+ pJson.angle,
							opacity : 0.8
						}).addtoLevel("Shapes",thisLayer);
						thisLayer._groupChildsShape.push(ellipsshape);
					});
				break;
			}
		}
		this._groupChildsShape.show();
		console.log('anjing', thisLayer.typeCanvas)
		if(thisLayer.typeCanvas === 'section') {
			this.setViewBox(this.width,this.height);
		}
		this.moveView("move-center");

		seatMap.DisplayingObject.ID = ObjJSON.id;

	};

	p.openfromJSONChild = function(pJSON){
		//this.groupObjects.initialize();
		this.levels.initialize();
		this.paper.clear();
		this._state = "stateThumb";

		$("#InfoBooking").hide();
		this._zoom = 1;
		$("#zoomSlider").slider("value",1);
		this._scaleSection = 1;
		this._seatSize = seatMap.ChartLayer.stepGrid * 2;
		this._seatSeparation = seatMap.ChartLayer.stepGrid * 2;

		var ObjJSON = JSON.parse(pJSON.chartJSON);

		if(!ObjJSON || !ObjJSON.objects) return;

		this._list =JSON.parse(pJSON.category);
		this.categories = new seatMap.Categories(this._list);

		// this._baseData = JSON.parse(localStorage.getItem('bookBaseData'));
		this._bookedData = {
			bookedOwn : [],
			soldOwn : [],
			bookedOther : [],
			soldOther : [],
			bookedOwnStand : {},
			soldOwnStand : {},
			bookedOtherStand : {},
			soldOtherStand : {},
		};
		//this._isManager = ObjJSON.manager;
		// this._isManager = this._baseData.userType=='manager';
		this._isManager = false;

		this._moveCenter();
		var area = {
			"width" : ObjJSON.width,
			"height" : ObjJSON.height,
			"x":(this.width/2-ObjJSON.width/2),
			"y":(this.height/2-ObjJSON.height/2)
		};
		this._area = area;

		var thisLayer = this;
		this.setScaleSection(ObjJSON.objects['section'][0].scaleInside);
		// for(var kind in ObjJSON.objects){
		// 	switch(kind){
		// 		case "section":
		// 			ObjJSON.objects[kind].forEach(function(objJson){
		// 				var inst = seatMap[seatMap.ChartLayer.capitalize(kind)].makeInstancefromJSON(objJson,area,thisLayer);
		// 				inst.setId(objJson.id);
		// 				inst.drawing(area);
		// 				thisLayer.groupObjects.addObject(inst);
		// 			});
		// 			break;
		// 		case "background":
		// 			ObjJSON.objects[kind].forEach(function(objJson){
		// 				var inst = thisLayer.paper.image(objJson.url, objJson.posi.x+area.x, objJson.posi.y+area.y, objJson.width , objJson.height).attr({
		// 					transform : "...R"+objJson.angle,
		// 				}).addtoLevel("Background",thisLayer);
		// 			});
		// 			break;
		// 		case "textShape":
		// 			ObjJSON.objects[kind].forEach(function(objJson){
		// 				var textshape =  thisLayer.paper.text(objJson.posi.x+area.x,objJson.posi.y+area.y, objJson.text).attr({
		// 					"font-size" : objJson.size,
		// 					fill : objJson.color,
		// 					transform : "...R"+objJson.angle,
		// 					opacity : 0.8
		// 				}).addtoLevel("Labels",thisLayer);
		// 				thisLayer._groupChildsShape.push(textshape);
		// 			});
		// 			break;
		// 		case "rectShape":
		// 			ObjJSON.objects[kind].forEach(function(pJson){
		// 				var rectshape = thisLayer.paper.rect(pJson.posi.x+area.x-pJson.width/2,pJson.posi.y+area.y-pJson.height/2,pJson.width,pJson.height,pJson.radius).attr({
		// 					"stroke-width" : pJson.strokeWidth,
		// 					stroke : pJson.strokeColor,
		// 					fill : pJson.fillColor,
		// 					transform : "...R"+ pJson.angle,
		// 					opacity : 0.8
		// 				}).addtoLevel("Shapes",thisLayer);
		// 				thisLayer._groupChildsShape.push(rectshape);
		// 			});
		// 			break;
		// 		case "ellipseShape":
		// 			ObjJSON.objects[kind].forEach(function(pJson){
		// 				var ellipsshape = thisLayer.paper.ellipse(pJson.posi.x+area.x,pJson.posi.y+area.y,pJson.width/2,pJson.height/2).attr({
		// 					"stroke-width" : pJson.strokeWidth,
		// 					stroke : pJson.strokeColor,
		// 					fill : pJson.fillColor,
		// 					transform : "...R"+ pJson.angle,
		// 					opacity : 0.8
		// 				}).addtoLevel("Shapes",thisLayer);
		// 				thisLayer._groupChildsShape.push(ellipsshape);
		// 			});
		// 			break;
		// 	}
		// }
		// this._groupChildsShape.show();

		this.setViewBox(this.width,this.height);

		this.moveView("move-center");

		seatMap.DisplayingObject.ID = ObjJSON.id;

	};

	p.setViewBox = function(pWidth, pHeight){
		this.width = pWidth;
		this.height = pHeight;
		this.paper.setSize(this.width, this.height);

		var area = this._area;

		var margin = this.isMobile() ? 20: 30,
			ratio = this.width/this.height,
			ratioX = area.width/this.width,
			ratioY = area.height/this.height;

		if(this.width<area.width){
			if(ratioX < ratioY){
				this._PVy = area.y - margin;
				this._PVh = area.height + 2*margin;

				this._PVw = this._PVh * ratio;
				this._PVx = area.x - (this._PVw-area.width)/2;
			}else{
				this._PVx = area.x - margin;
				this._PVw = area.width + 2*margin;

				this._PVh = this._PVw/ratio;
				this._PVy = area.y - (this._PVh-area.height)/2;
			}
		}else{
			this._PVx = 0;
			this._PVy = 0;
			this._PVw = this.width;
			this._PVh = this.height;
		}

		var _PVx = this._PVx + this._PVw * (1-1/this._zoom)/2,
			_PVy = this._PVy + this._PVh * (1-1/this._zoom)/2,
			_PVw = this._PVw / this._zoom,
			_PVh = this._PVh / this._zoom;

		this.paper.setViewBox(_PVx,_PVy,_PVw,_PVh,true);

		var activeSection = this.selectedObjects.getSelectedSection();
		if(activeSection){
			this.setState('stateBooking');
			activeSection.updatingShow();
		}

	};

	p.moveView = function(pDir){
		var dx = 0, dy = 0;
		switch(pDir){
			case 'move-up':
				dy = -50;
			break;
			case 'move-down':
				dy = 50;
			break;
			case 'move-left':
				dx = -50;
			break;
			case 'move-right':
				dx = 50;
			break;
			case 'move-center':
				this._zoom = 1;
				if(this._state=='stateThumb'){
					this.paper.setViewBox(this._PVx,this._PVy,this._PVw,this._PVh,true);
				}
				$("#zoomSlider").slider("value",1);
				if(this._state=='stateBooking'){
					this.setState('stateBooking');
					this.selectedObjects.getSelectedSection().updatingShow();
				}
			break;
		}
		if(pDir!='move-center'){
			var viewBox = this.paper._viewBox;
			var nx = viewBox[0] - dx,
				ny = viewBox[1] - dy,
				w = this.width/this._zoom,
				h = this.height/this._zoom;
			if(nx>this._PVx && nx+w<this._PVx+this._PVw && ny>this._PVy && ny+h<this._PVy+this._PVh){
				this.paper.setViewBox(nx,ny,w,h);
				if(this._state=='stateBooking') this.selectedObjects.getSelectedSection().updatingShow();
			}
		}
	}

	p.possibleBook = function(){
		if(this._baseData.limitQuantity){
			return this._baseData.quantity[this._selSectionID] > this.selectedObjects.getCtBookedSeats();
		}else{
			return true;
		}
	};

	p.isMoving = function(){
		return this._moving;
	};

	p.isManger = function(){
		return this._isManager;
	};

	p.isMobile = function() {
		if( navigator.userAgent.match(/Android/i)
		 || navigator.userAgent.match(/webOS/i)
		 || navigator.userAgent.match(/iPhone/i)
		 || navigator.userAgent.match(/iPad/i)
		 || navigator.userAgent.match(/iPod/i)
		 || navigator.userAgent.match(/BlackBerry/i)
		 || navigator.userAgent.match(/Windows Phone/i)
		 ){
			return true;
		 }
		 else {
			return false;
		 }
	}

	//private
	p._setCursor = function(type) {	this.canvas.css("cursor", type); };

	p._setCursorToNone = function(){
		this.canvas.css("cursor", "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAAAZdEVYdFNvZnR3YXJlAFBhaW50Lk5FVCB2My41LjbQg61aAAAADUlEQVQYV2P4//8/IwAI/QL/+TZZdwAAAABJRU5ErkJggg=='), none")
	}

	p._initLayer = function(){
		var thisLayer = this;
		//this._moveCenter();
		// this.canvas.on("contextmenu", function(){ return false; });
		var eventDownName = "mousedown",
			eventMoveName = "mousemove",
			eventUpName = "mouseup";
		if(this.isMobile()) {
			var eventDownName = "tapstart";
			var eventMoveName = "tapmove";
			var eventUpName = "tapend";
		}

		this.canvas.on(eventDownName, function(ev) {
			if(!thisLayer._up){
				thisLayer._moveStart = true;
				thisLayer._up = true;
			}
			thisLayer._ptSt = seatMap.Point.getPointfromEvent(ev,thisLayer);
		});
		this.canvas.on(eventMoveName, function(ev){
			ev.preventDefault();
			if(thisLayer._moveStart){
				thisLayer._moving = true;
				if(thisLayer._state=='stateBooking'){
					var currPt = seatMap.Point.getPointfromEvent(ev,thisLayer);
					var dx = currPt.x-thisLayer._ptSt.x, dy = currPt.y-thisLayer._ptSt.y;
					var viewBox = thisLayer.paper._viewBox;
					var nx = viewBox[0] - dx,
						ny = viewBox[1] - dy,
						w = thisLayer.width/thisLayer._zoom,
						h = thisLayer.height/thisLayer._zoom;
					if(nx>thisLayer._PVx && nx+w<thisLayer._PVx+thisLayer._PVw && ny>thisLayer._PVy && ny+h<thisLayer._PVy+thisLayer._PVh){
						thisLayer.paper.setViewBox(nx,ny,w,h);
						thisLayer._ptSt = currPt;
						thisLayer.selectedObjects.getSelectedSection().updatingShow();
					}
				}else{
					var currPt = seatMap.Point.getPointfromEvent(ev,thisLayer);
					var dx = (currPt.x-thisLayer._ptSt.x)*thisLayer._zoom, dy = (currPt.y-thisLayer._ptSt.y)*thisLayer._zoom;
					var viewBox = thisLayer.paper._viewBox;
					var nx = viewBox[0] - dx,
						ny = viewBox[1] - dy,
						w = viewBox[2],
						h = viewBox[3];
					if(nx>thisLayer._PVx && nx+w<thisLayer._PVx+thisLayer._PVw && ny>thisLayer._PVy && ny+h<thisLayer._PVy+thisLayer._PVh){
						thisLayer.paper.setViewBox(nx,ny,w,h);
						thisLayer._ptSt = currPt;
					}
				}
			}
		});
		this.canvas.on(eventUpName, function(ev){
			if(thisLayer._state=='stateBooking'){
				if(thisLayer._moving && thisLayer.selectedObjects.getSelectedSection()) thisLayer.selectedObjects.getSelectedSection().updatingShow();
				thisLayer._up = false;
			}else{
				thisLayer._up = false;
			}
			thisLayer._moveStart = false;
			thisLayer._moving = false;
			thisLayer._ptSt = null;
		});
	};

	p._moveCenter = function(){
		window.scrollTo(this.width / 2, this.height / 2);
	};

	// static
	ChartLayer.convertAngToRadi = function(pAng){
		return pAng/180*Math.PI;
	}
	ChartLayer.capitalize = function (string) {
		return string.charAt(0).toUpperCase() + string.slice(1);
	}
	ChartLayer.convertToNumber = function (pVal) {
		var base = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ',
			i, j, result = 0;

		for (i = 0, j = pVal.length - 1; i < pVal.length; i += 1, j -= 1) {
			result += Math.pow(base.length, j) * (base.indexOf(pVal[i]) + 1);
		}
		return result;
	};
	ChartLayer.convertToString = function (pNum) {
		var baseChar = ("A").charCodeAt(0),
			letters = "";

		do {
			pNum -= 1;
			letters = String.fromCharCode(baseChar + (pNum % 26)) + letters;
			pNum = (pNum / 26) >> 0;
		} while (pNum > 0);

		return letters;
	}

	ChartLayer.stepGrid = 4;

	seatMap.ChartLayer = ChartLayer;
}());



(function(){
	function Section(pLayer){

		this.DisplayingObject_constructor();

		//public
		this.type = "section";
		this.points = [];
		this.QPoints = [];
		this.completed = true;

		//private
		this._id = -1;
		this._layer = pLayer;
		this._label = "section";
		this._labelSize = 24;
		this._labelColor = '#333';
		this._angleLabel = 0;
		this._leftLabel	= 0;
		this._topLabel	= 0;
		this._scaleInside = 1;

		this._standArea = false;
		this._standSeats = 0;
		this._reservedSeats = 0;
		this._cateStandSeats = -1;
		this._bookedStand = {hold:0, sold:0};

	////////////////????/////////////////////
		this._drawingSection = null;

		this._bound = null;
		this._prevAngle = 0;

		this._category = null;
		this._nameLevel = 'Sections';

		this._isCreated = false;

		this._childsJSON = "";
		this._childs = {
			row : [],
			textShape : [],
			rectShape : [],
			ellipseShape : [],
			tableRect : [],
			tableCircle : [],
			background : "",
			focalpoint :[]
		};
		this._groupChildsShape = this._layer.paper.set();

		this._ctBookedSeats = 0;
		this._ctSoldSeats = 0;
		this._CtSoldSeatsLabel = [];
		this._price = 0;
	/////////////////////////////////
	};
	var p = seatMap.extend(Section, seatMap.DisplayingObject);

	// public method

	p.drawing = function(pBound){
		if (this.points.length == 0) return ;
		if(this._drawingSection) this._layer.levels.remove(this._drawingSection,this._nameLevel);
		var str = this._pathString();
		var shape = this._layer.paper.path(str).attr({
			stroke: this.completed ? "#5c5c5c" : "red",
			"stroke-width": 2,
			"stroke-linejoin": "round",
			opacity : 0.8
		}).setCompletedAttr(this.completed, {
			fill: this._fillColor
		});
		this._drawingSection = this._layer.paper.set();
		this._drawingSection.push(shape);

		if(this.completed){
			var bbox = shape.getBBox();
			var LBRotateCenter = new seatMap.Point(bbox.x+bbox.width/2, bbox.y+bbox.height/2);
			var lPosi = bbox.x+bbox.width/2 + this.getLabelLeftPosi();
			var tPosi = bbox.y+bbox.height/2 + this.getLabelTopPosi();
			var txtposi = new seatMap.Point(lPosi,tPosi);
			var realPosi = seatMap.Point.rotated(txtposi.x,txtposi.y, this._angle, LBRotateCenter, this._layer);
			var label = this._layer.paper.text(realPosi.x,realPosi.y,this._label).attr({
				"font-size" : this.getLabelSize(),
				"transform" : "...R" + (this._angle + this._angleLabel+360)%360
			});
			this._drawingSection.push(label);
		}

		this._drawingSection.addtoLevel(this._nameLevel, this._layer);

		this._bindEvent();
	};

	p.updatingShow = function(){
		this._showChildren();
	};

	p.selected = function(){
		this._layer.selectedObjects.setSection(this);
		var thisSection = this;
		$('.loading_title').show();
		setTimeout(function(){
			thisSection._showChildren();
			// console.log(thisSection._childsJSON);
			$('.loading_title').hide();

			if(thisSection._standArea){
				$("#InfoBooking").removeClass("seat").addClass("stand");

				/*otherBookedStand = thisSection._layer.getBookedStands(thisSection._id);
				$("#entireQty").text(thisSection._standSeats);
				$("#nonAvail").text(otherBookedStand.hold + " / " + otherBookedStand.sold); */

				var ctAvailSeats = thisSection.getAvailStandSeat(),
					infoStand = !!thisSection._cateStandSeats ? ' ( ' + thisSection._cateStandSeats.price +' $/seat ) ' : 'none';

				var likeQty = thisSection._layer.getSectionQty(thisSection._id);
				$("#likeQty").text(likeQty);

				$('#bookQty').val(thisSection._bookedStand.hold);
				window.parent.$("#priceBook").text(thisSection._price);

				if(likeQty==0 || ctAvailSeats==0){
					$('#bookQty').attr('disabled','disabled');
				}else{
					$('#bookQty').removeAttr('disabled');
				}
				$("#availabelQty").text(ctAvailSeats + infoStand);

			}else{
				$("#InfoBooking").removeClass("stand").addClass("seat");
				$("#likeQty").text(thisSection._layer.getSectionQty(thisSection._id));
				window.parent.$("#sectionQty").val(thisSection._label);
				window.parent.$("#selectedQty").val(thisSection._CtSoldSeatsLabel.sort(collator.compare).join(','));
				window.parent.$("#priceBook").val(thisSection._layer._baseData.currency + ' ' + ThousandSeparator(thisSection._price));
			}
			$("#InfoBooking").show();
		},50);
	};

	p.selectedBySection = function(input){
		this._layer.selectedObjects.setSection(input);
		var thisSection = input;
		$('.loading_title').show();
		setTimeout(function(){
			thisSection._showChildren();
			// thisSection._layer.onClickSection(this)
			// console.log(thisSection._childsJSON);
			$('.loading_title').hide();

			if(thisSection._standArea){
				$("#InfoBooking").removeClass("seat").addClass("stand");

				/*otherBookedStand = thisSection._layer.getBookedStands(thisSection._id);
				$("#entireQty").text(thisSection._standSeats);
				$("#nonAvail").text(otherBookedStand.hold + " / " + otherBookedStand.sold); */

				var ctAvailSeats = thisSection.getAvailStandSeat(),
					infoStand = !!thisSection._cateStandSeats ? ' ( ' + thisSection._cateStandSeats.price +' $/seat ) ' : 'none';

				var likeQty = thisSection._layer.getSectionQty(thisSection._id);
				$("#likeQty").text(likeQty);

				$('#bookQty').val(thisSection._bookedStand.hold);
				window.parent.$("#priceBook").text(thisSection._price);

				if(likeQty==0 || ctAvailSeats==0){
					$('#bookQty').attr('disabled','disabled');
				}else{
					$('#bookQty').removeAttr('disabled');
				}
				$("#availabelQty").text(ctAvailSeats + infoStand);

			}else{
				$("#InfoBooking").removeClass("stand").addClass("seat");
				$("#likeQty").text(thisSection._layer.getSectionQty(thisSection._id));
				window.parent.$("#sectionQty").val(thisSection._label);
				window.parent.$("#selectedQty").val(thisSection._CtSoldSeatsLabel.sort(collator.compare).join(','));
				window.parent.$("#priceBook").val(thisSection._layer._baseData.currency + ' ' + ThousandSeparator(thisSection._price));
			}
			$("#InfoBooking").show();
		},50);
	};

	p.released = function(){
		this._hideChildren();
	};

	p.getasShape = function(){
		if(this._drawingSection) return this._drawingSection;
		else console.log("check here");
	};

	p.getLabelSize = function(){ return this._labelSize; };
	p.setLabelSize = function(pSize){ this._labelSize = pSize; };

	p.getLabelColor = function(){ return this._labelColor; };
	p.setLabelColor = function(pColor){ return this._labelColor = pColor; };

	p.setAngleLabel = function(pAng){ this._angleLabel = this._angleLabel+pAng; };
	p.getAngleLabel = function(){ return this._angleLabel; };

	p.getLabelLeftPosi = function(){ return this._leftLabel; };
	p.setLabelLeftPosi = function(pLPosi){ this._leftLabel = pLPosi; };

	p.getLabelTopPosi = function(){ return this._topLabel; };
	p.setLabelTopPosi = function(pRPosi){ this._topLabel = pRPosi; };

	p.setChilds = function(pChilds){
		this._childsJSON = pChilds;
		this._childs['background'] = pChilds.background;
	}
	p.getChilds = function(){
		return this._childsJSON;
	};

	p.setScaleInside = function(pScale){
		this._scaleInside = pScale;
	}

	p.getScaleInside = function(){
		return this._scaleInside;
	};

	p.setFillColor = function(pColor){
		this._fillColor = pColor || "rgba(255,255,255,1)";
	}
	p.setStandSeats = function(pStand, pQty, pCate, pQtyReserved){
		this._standArea = pStand;
		this._standSeats = Number(pQty);
		this._cateStandSeats = this._layer.categories.getCategory(Number(pCate));
		this._reservedSeats = Number(pQtyReserved || 0);
	}
	p.getCatetoryStandSeats = function(){
		return this._cateStandSeats;
	};
	p.releaseBookedStandSeats = function(){
		this._ctSoldSeats = 0;
		this._CtSoldSeatsLabel = [];
		this._bookedStand = {hold:0, sold:0};
		this._price = 0;
	};

	p.getAvailStandSeat = function(){
		var otherBookedStand = this._layer.getBookedStands(this._id),
			managerBookCt = 0, userBookCt = 0,
			ctAvailSeats = 0;//this._standSeats - otherBookedStand.hold - otherBookedStand.sold;

		for(var cate in otherBookedStand.hold){
			managerBookCt += otherBookedStand.hold[cate].stand[1];
			userBookCt += otherBookedStand.hold[cate].stand[0];
		}
		for(var cate in otherBookedStand.sold){
			managerBookCt += otherBookedStand.sold[cate].stand[1];
			userBookCt += otherBookedStand.sold[cate].stand[0];
		}
		ctAvailSeats = this._standSeats - userBookCt;
		if(managerBookCt>this._reservedSeats) ctAvailSeats -= managerBookCt-this._reservedSeats;
		ctAvailSeats -= (!this._layer.isManger() ? this._reservedSeats : managerBookCt);

		return ctAvailSeats-this._ctSoldSeats; //pending  available count
	}
	p.setHoldingSeats = function(pQty){
		console.log('nyoh')
		this._ctBookedSeats = pQty;
		this._bookedStand.hold = pQty;
		//this._layer.selectedObjects.
		var stroke = "black", strokewidth = 1;
		if(this._ctBookedSeats>0){
			stroke = "blue";
			strokewidth = 2;
		}
		this._drawingSection[0].attr({
			stroke : stroke,
			"stroke-width" : strokewidth
		});
		return {
				key : this.getId(),
				value : {
						[this._cateStandSeats.key]:{stand : [this._standSeats, this._reservedSeats, (this._layer.isManger() ? 1 : 0) , pQty] }
					}
			};
	}

	p.updatingBookedState = function(pType,pID){
		var category = this._layer.groupObjects.getCategorySeat(pID),
			price = this._layer.categories.getCategory(category).price
		switch(pType){
			case "add":
				this._ctBookedSeats++;
				this._price += price;
			break;
			case "remove":
				this._ctBookedSeats--;
				this._price -= price;
				if(this._ctBookedSeats<0){
					console.log(this._ctBookedSeats+"error");
					this._ctBookedSeats=0;
				}
			break;
		}
		var stroke = "black", strokewidth = 1;
		if(this._ctBookedSeats>0){
			stroke = "blue";
			strokewidth = 2;
		}
		this._drawingSection[0].attr({
			stroke : stroke,
			"stroke-width" : strokewidth
		});
	};
	p.boughtSeats = function(){
		this._ctSoldSeats += this._ctBookedSeats;
		this._ctBookedSeats = 0;
	};
	p.releaseSelectedSeats = function(){
		this._ctBookedSeats = 0;
		this._price = 0;
		this._CtSoldSeatsLabel = [];

		this._bookedStand = {hold:0, sold:0};

		var stroke = "black", strokewidth = 1;
		if(this._ctBookedSeats>0){
			stroke = "blue";
			strokewidth = 2;
		}
		this._drawingSection[0].attr({
			stroke : stroke,
			"stroke-width" : strokewidth
		});

		$('#book').hide();
		window.parent.$('.select-seats').val(0);
		var bookJson = JSON.stringify(this._layer.getBookedSeats());
		window.parent.$('#seatmapJSON').val(bookJson);
		window.parent.$('#sectionQty').val('');
		window.parent.$('#selectedQty').val('');
		window.parent.$('#priceBook').val('');
		window.parent.$('#book').hide();

		this.setParentPriceType();
	};

	p.setParentPriceType = function() {
		var json = this._layer.getBookedSeats().bookedJSON;
		if (!$.isEmptyObject(json)) {
			if (window.parent.$('.container-seatmap-price-type').is(':hidden')) {
				var firstSeats = json[Object.keys(json)[0]];
				var firstCategory = Object.keys(firstSeats)[0];
				if (firstCategory !== undefined) {
					var priceType = window.parent.$('.div-price-type-' + firstCategory);
					if (priceType.length > 0 && priceType.children().html() != '') {
						priceType.children().clone().appendTo(window.parent.$('.div-seatmap-price-type'));
						window.parent.$('.container-seatmap-price-type').show();
					}
				}
			}
		} else {
			window.parent.$('.container-seatmap-price-type').hide();
			window.parent.$('.div-seatmap-price-type').html('');
		}
	}

	p.getCtBookedSeats = function(){
		return this._ctSoldSeats+this._ctBookedSeats;
	};
	p.getPriceStand = function(pQtySeats){
		this._price = this._cateStandSeats.price * pQtySeats;
		return this._price;
	};
	p.getPrice = function(){
		return this._price;
	};

	//private method

	p._hideChildren = function(){
		if(this._drawingSection[1])this._drawingSection[1].show();
		var thisSection = this;
		for(kind in this._childs){
			if(kind!="background"){
				thisSection._childs[kind].forEach(function(inst){
					inst.remove();
				});
			}
		}
		this._groupChildsShape.hide();
	};
	p._showChildren = function(){
		if(this._drawingSection[1]) this._drawingSection[1].hide();
		if(!this._isCreated){
			this._groupChildsShape.push(this._drawingSection[0].getBBox());
			this._bound = this._drawingSection[0].getBBox();

			cx = this._bound.cx;
			cy = this._bound.cy;

			var VC = new seatMap.Point(5000,5000);
				diffAxis = {x : cx - VC.x, y : cy - VC.y};
			var thisSection = this;
			for(var kind in this._childsJSON){
				switch(kind){
					case "row": case "tableRect": case "tableCircle":
						this._childsJSON[kind].forEach(function(objJson){
							objJson.seats.forEach(function(seat){
								var pt = new seatMap.Point(seat.x, seat.y);
								pt.scale(1/thisSection._scaleInside,VC);
								seat.x = pt.x;
								seat.y = pt.y;
							});
							switch(kind){
								case "row":
								break;
								case "tableRect":
									var pt = new seatMap.Point(objJson.posi.x, objJson.posi.y);
									pt.scale(1/thisSection._scaleInside,VC);
									objJson.posi.x = pt.x;
									objJson.posi.y = pt.y;
									objJson.width/=thisSection._scaleInside;
									objJson.height/=thisSection._scaleInside;
								break;
								case "tableCircle":
									var pt = new seatMap.Point(objJson.posi.x, objJson.posi.y);
									pt.scale(1/thisSection._scaleInside,VC);
									objJson.posi.x = pt.x;
									objJson.posi.y = pt.y;
									objJson.radius/=thisSection._scaleInside;
								break;
							}
							var inst = seatMap[seatMap.ChartLayer.capitalize(kind)].makeInstancefromJSON(objJson,diffAxis,thisSection._layer);
							inst.setId(objJson.id);
							inst.setParent(thisSection);
							inst.drawing();
							thisSection._childs[kind].push(inst);
						});
					break;
					case "textShape":
						this._childsJSON[kind].forEach(function(objJson){
							var pt = new seatMap.Point(objJson.posi.x, objJson.posi.y);
							pt.scale(1/thisSection._scaleInside,VC);
							objJson.posi.x = pt.x;
							objJson.posi.y = pt.y;
							objJson.size/=thisSection._scaleInside;
							var textshape =  thisSection._layer.paper.text(objJson.posi.x+diffAxis.x,objJson.posi.y+diffAxis.y, objJson.text).attr({
								"font-size" : objJson.size,
								fill : objJson.color,
								transform : "...R"+objJson.angle,
								opacity : 0.8
							}).addtoLevel("Labels",thisSection._layer);
							thisSection._groupChildsShape.push(textshape);
						});
					break;
					case "rectShape":
						this._childsJSON[kind].forEach(function(pJson){
							var pt = new seatMap.Point(pJson.posi.x, pJson.posi.y);
							pt.scale(1/thisSection._scaleInside,VC);
							pJson.posi.x = pt.x;
							pJson.posi.y = pt.y;
							pJson.height/=thisSection._scaleInside;
							pJson.width/=thisSection._scaleInside;
							var rectshape = thisSection._layer.paper.rect(pJson.posi.x+diffAxis.x-pJson.width/2,pJson.posi.y+diffAxis.y-pJson.height/2,pJson.width,pJson.height,pJson.radius).attr({
								"stroke-width" : pJson.strokeWidth,
								stroke : pJson.strokeColor,
								fill : pJson.fillColor,
								transform : "...R"+ pJson.angle,
								opacity : 0.8
							}).addtoLevel("Shapes",thisSection._layer);
							thisSection._groupChildsShape.push(rectshape);
						});
					break;
					case "ellipseShape":
						this._childsJSON[kind].forEach(function(pJson){
							var pt = new seatMap.Point(pJson.posi.x, pJson.posi.y);
							pt.scale(1/thisSection._scaleInside,VC);
							pJson.posi.x = pt.x;
							pJson.posi.y = pt.y;
							pJson.height/=thisSection._scaleInside;
							pJson.width/=thisSection._scaleInside;
							var ellipsshape = thisSection._layer.paper.ellipse(pJson.posi.x+diffAxis.x,pJson.posi.y+diffAxis.y,pJson.width/2,pJson.height/2).attr({
								"stroke-width" : pJson.strokeWidth,
								stroke : pJson.strokeColor,
								fill : pJson.fillColor,
								transform : "...R"+ pJson.angle,
								opacity : 0.8
							}).addtoLevel("Shapes",thisSection._layer);
							thisSection._groupChildsShape.push(ellipsshape);
						});
					break;

				}
			}
			this._isCreated = true;
		}else{
			for(var kind in this._childs){
				if(kind!="background"){
					for(var i = 0; i<this._childs[kind].length; i++){
						this._childs[kind][i].drawing();
					}
				}
			}
		}
		this._groupChildsShape.show();

	};

	p._pathString = function(){
		/* var str = "M" + this.points[0].x + "," + this.points[0].y;
		for (var i = 1; i < this.points.length; ++i) {
			str += "L" + this.points[i].x + "," + this.points[i].y;
		}
		if (this.completed) str += "Z";
		return str; */
		var str = "M" + this.points[0].x + "," + this.points[0].y;
		for (var i = 1; i < this.points.length; ++i) {
			str += "Q" + this.QPoints[i-1].x + "," + this.QPoints[i-1].y + ',' + this.points[i].x + "," + this.points[i].y;
		}

		if (this.completed){
			str += "Q" + this.QPoints[i-1].x + "," + this.QPoints[i-1].y + ',' + this.points[0].x + "," + this.points[0].y+"Z";
		}
		return str;

	};

	p._getColor = function(){
		if (this.category) {
			return this.category.color
		}else{
			return "#fff";
		}
	}

	p._bindEvent =  function(){
		if(this.completed && this._drawingSection){
			var thisSection = this;
			this._drawingSection.mousedown(function(ev){
				this.selected = true;
			}).mouseover(function(ev){
				if(thisSection._layer.getState()!="stateBooking" && thisSection._layer.getState()!="statePaying"){
					// this.attr({cursor : "move"});
					let classSection = `${thisSection._layer.typeCanvas}-container`;
					if(thisSection._layer.getZoom() > 1){
						classSection+= ' cursor-grabbing';
					}
					this.attr('class',classSection);
					$('.BGSection img').attr('src',thisSection._childs.background);
					$('.BGSection').css({'left': (ev.clientX+10), 'top': (ev.clientY-100)});
					$('.BGSection').show();
				}
			}).mousemove(function(ev){
				if(thisSection._layer.getState()!="stateBooking"){
					$('.BGSection').css({'left': (ev.clientX+20), 'top': (ev.clientY-30)});
					this.selected = false;
				}
			}).mouseout(function(){
				if(thisSection._layer.getState()!="stateBooking") $('.BGSection').hide();
			}).click(function(ev){
				console.log('click goblock')
				if(this.selected){
					var currState = thisSection._layer.getState();
					if(currState=="stateThumb"){
						// thisSection.selected();
						thisSection._layer.onClickSection(thisSection)
					}
				}
			});
		}
	}

	//static methods

	Section.getPoints = function(pSection){
		return pSection.points;
	}

	Section.makeInstancefromJSON = function(pJson,pBound,pLayer){
		var Inst = new seatMap.Section(pLayer);
		/* pJson.points.forEach(function(el){
			var pt = new seatMap.Point(el.x+pBound.x,el.y+pBound.y);
			pt.bindToParent(Inst);
			Inst.points.push(pt);
		}); */

		for(var i=0; i<pJson.points.length; i++){
			var pt = new seatMap.Point(pJson.points[i].x+pBound.x,pJson.points[i].y+pBound.y);
			pt.bindToParent(Inst);
			Inst.points.push(pt);

			pt = new seatMap.Point(pJson.QPoints[i].x+pBound.x,pJson.QPoints[i].y+pBound.y);
			pt.bindToParent(Inst);
			Inst.QPoints.push(pt);
		}

		Inst.setId(pJson.id);
		Inst.setLabel(pJson.label);
		Inst.setLabelSize(pJson.labelSize);
		Inst.setAngleLabel(pJson.angleLabel);
		Inst.setLabelLeftPosi(pJson.leftLabel);
		Inst.setLabelTopPosi(pJson.topLabel);
		Inst.setChilds(pJson.childs);
		Inst.setFillColor(pJson.fillColor);
		Inst.setScaleInside(pJson.scaleInside);
		Inst.setStandSeats(pJson.standArea, pJson.standSeat, pJson.cateStandSeats, pJson.reservedSeat);
		return Inst;
	};

	seatMap.Section = seatMap.promote(Section, 'DisplayingObject');
}());

(function(){
	function TextShape(pLayer){

		this.DisplayingObject_constructor();

		//public
		this.type = "textShape";


		//private
		this._layer = pLayer;

		this._position = null;
		this._angle = 0;
		this._rotatingAngle = 0;
		this._text = "Text";
		this._textSize = 20;
		this._textColor = '#333';

		this._nameLevel = 'Labels';

		this._shapeText = null;

	};
	var p = seatMap.extend(TextShape, seatMap.DisplayingObject);

	//publick
	p.drawing = function(pBound){
		if(this._shapeText) this.remove();
		this._shapeText = this._layer.paper.text(this._position.x,this._position.y, this._text).attr({
			"font-size" : this._textSize,
			fill : this._textColor,
			angle : this._angle,
			opacity : 0.8
		}).addtoLevel(this._nameLevel,this._layer);
	};

	p.getasShape = function(){
		return this._shapeText;
	};

	p.setLabel = function(pText){
		this._text = pText;
	};
	p.getLabel = function(){
		return this._text;
	};

	p.setLabelSize = function(pSize){
		this._textSize = pSize;
	};
	p.getLabelSize  = function(){
		return this._textSize;
	};

	p.setColor = function(pColor){
		this._textColor = pColor;
	};
	p.getColor = function(){
		return this._textColor;
	};

	p.setPosition = function(pPosi){
		this._position = pPosi;
	};

	p.remove = function(){
		this._layer.levels.remove(this._shapeText, this._nameLevel)
	};

	//static methods

	TextShape.makeInstancefromJSON = function(pJson,pBound,pLayer){
		var Inst = new seatMap.TextShape(pLayer);
		Inst.setLabel(pJson.text);
		Inst.setPosition(new seatMap.Point(pJson.posi.x+pBound.x,pJson.posi.y+pBound.y));
		Inst.setAngle(pJson.angle);
		Inst.setLabelSize(pJson.size);
		Inst.setColor(pJson.color);
		Inst.setId(pJson.id);
		return Inst;
	};

	seatMap.TextShape = seatMap.promote(TextShape, 'DisplayingObject');
}());

(function(){
	function Row(pPoints,pLayer){
		this.DisplayingObject_constructor();

		//public
		this.type = "row";

		//private
		this._parent = null;
		this._layer = pLayer;
		this._id = -1;
		this._seats = [];
		this._posiSeats = [];
		this._isCreated = false;

		this._nameLevel = 'Seats';

		this._angle = 0;
		this._prevAngle = 0;
		this._curve = 0;
		this._prevDirAngle = 0;
		this._numberText = null;
		this._extendHandle = [];

		this._ep = 1;
		this._labelSize = 20/this._layer.getScaleSection();
		this._label = "";
		this._labelShape = null;
		this._lvlLabel = "OutlineLabels";
		this._boundShape = null;
		this._lvlBound = "Sections";
		this._optionLabel = new seatMap.SettingLabel();

		this._propSeats = null;

		this._addPositionSeats(pPoints);
	}

	var p = seatMap.extend(Row, seatMap.DisplayingObject);

	// public
	p.setParent = function(pParent){
		this._parent = pParent;
	};
	p.getParent = function(){
		return this._parent;
	}

	p.setCurve = function(pCurve){
		this._curve = pCurve;
	};

	p.setPropSeats = function(pSeats){
		this._propSeats = pSeats;
	}

	p.getAngle = function(){
		return this._angle;
	};

	p.getLayer = function(){
		return this._layer;
	}

	p.checkPossibleBooking = function(pSeat){
		var i = this._seats.indexOf(pSeat);
		if(i!=0 && i!=this._seats.length-1){
			if(this._layer.getBookingState(this._seats[i-1].getId())==0 && this._layer.getBookingState(this._seats[i+1].getId())==0){
				if(i==1 || i==this._seats.length-2){
					return false;
				}else{
					if(this._layer.getBookingState(this._seats[i-2].getId())!=0 || this._layer.getBookingState(this._seats[i+2].getId())!=0){
						return false;
					}
				}
			}
		}
		return true;
	}

	p.drawing = function(){
		if(!this._isCreated){
			var posi = this._posiSeats[Math.floor(this._posiSeats.length/2)];
			if(this._posiSeats.length%2 == 0){
				var posi1 = this._posiSeats[this._posiSeats.length/2-1];
				posi = new seatMap.Point((posi.x+posi1.x)/2, (posi.y+posi1.y)/2);
			}
			this._shapeLabel = this._layer.paper.text(posi.x,posi.y,this._label).attr({
				"font-size" : this._labelSize,
				"transform" : "...R"+this._angle
			}).addtoLevel(this._lvlLabel,this._layer);
			this._shapeLabel.scale(1/this._parent.getScaleInside());
			this._seats = [];
			for(var i=0; i<this._posiSeats.length; i++){
				var seat = new seatMap.Seat(this._posiSeats[i],this);
				seat.setAngle(this._angle+this._prevAngle);
				seat.setLabel(this._propSeats[i].label ? this._propSeats[i].label : "-");
				seat.setId(this._propSeats[i].id);
				seat.setCategory(this._propSeats[i].category);
				seat.setReserved(this._propSeats[i].reserved);
				seat.drawing();

				this._seats.push(seat);
			}
			this._isCreated = true;
		}else{
			for(var i=0; i<this._seats.length; i++){
				this._seats[i].drawing();
			}
			this._shapeLabel.show();
		}
	}

	p.remove = function(){
		this._shapeLabel.hide();
		this._seats.forEach(function(seat){
			seat.remove();
		});
	};

	//private
	p._addPositionSeats = function(pPoints){
		var thisRow = this;
		this._posiSeats = pPoints;
		this._posiSeats.forEach(function(pSeatPt){
			pSeatPt.bindToParent(thisRow);
		});
	};


	// static

	Row.makeInstancefromJSON = function(pJsonObj,pEntireBBox,pLayer){
		var pts = [],categSeats = [];
		pJsonObj.seats.forEach(function(el){
			pts.push( new seatMap.Point(el.x+pEntireBBox.x,el.y+pEntireBBox.y) );
		});
		var Inst = new seatMap.Row(pts,pLayer);
		Inst.setLabel(pJsonObj.label);
		Inst.setCurve(pJsonObj.curve);
		Inst.setAngle(pJsonObj.angle);
		Inst.setId(pJsonObj.id);
		Inst.setPropSeats(pJsonObj.seats);
		return Inst;
	};

	seatMap.Row = seatMap.promote(Row, "DisplayingObject");
}());

(function(){
	function TableRect(pPoints,pLayer){
		this.DisplayingObject_constructor();

		//public
		this.type = "tableRect";

		//private
		this._parent = null;
		this._layer = pLayer;
		this._position = null;
		this._seats = [];
		this._posiSeats = pPoints;
		this._propSeats = {};

		this._groupShapes = null;
		this._nameLevel = 'Seats';

		this._kind = 2;
		this._NumChairs = 8;
		this._width = 120;
		this._height = 36;
		this._angle = 0;
		this._prevAngle = 0;

		this._ep = 1;
		this._table = null;
		this._labelSize = 20;
		this._label = "";
		this._labelShape = null;
		this._lvlLabel = "OutlineLabels";
		this._boundShape = null;
		this._lvlBound = "Sections";

		this._addPositionSeats(pPoints);

		this._isCreated = false;
	}

	var p = seatMap.extend(TableRect, seatMap.DisplayingObject);

	//public
	p.setParent = function(pParent){
		this._parent = pParent;
	};
	p.getParent = function(){
		return this._parent;
	}

	p.setPropSeats = function(pSeats){
		this._propSeats = pSeats;
	}

	p.getKind = function(){ return this._kind; };
	p.setKind = function(pKind){
		this._kind = pKind;
	};

	p.getCount = function(){ return this._NumChairs; };
	p.setCount = function(pCount){ this._NumChairs = pCount; };

	p.getWidth  = function(){ return this._width; };
	p.setWidth  = function(pWidth){ this._width = pWidth; };

	p.getHeight = function(){ return this._height; };
	p.setHeight = function(pHeight){ this._height = pHeight; };

	p.setPosition = function(pPosi){
		this._position = pPosi;
		this._position.bindToParent(this);
	};

	p.getLayer = function(){
		return this._layer;
	}

	p.getAngle = function(){
		return this._angle;
	};


	p.drawing = function(){
		if(!this._isCreated){
			for(var i=0; i<this._propSeats.length; i++){
				var seat = new seatMap.Seat(this._posiSeats[i],this);
				seat.setAngle(this._angle+this._prevAngle);
				seat.setLabel(this._propSeats[i].label ? this._propSeats[i].label : "-");
				seat.setId(this._propSeats[i].id);
				seat.setCategory(this._propSeats[i].category);
				seat.setReserved(this._propSeats[i].reserved);
				seat.drawing();

				this._seats.push(seat);
			}

			this._labelShape = this._layer.paper.text(this._position.x,this._position.y,this._label).attr({
				"font-size" : this._labelSize,
				"transform" : "...R"+this._angle
			}).addtoLevel(this._lvlLabel,this._layer);

			switch(this._kind){
				case 1: case 2:	//top&botton
					if(!this._table) this._table = this._layer.paper.rect(this._position.x-this._width/2,this._position.y-this._height/2,this._width,this._height).attr({
						"stroke-width" : 1,
						stroke : "#333",
						fill : "#fff",
						opacity : 0.8,
						transform : "...R"+this._angle
					}).addtoLevel(this._nameLevel,this._layer);
				break;
				case 3: case 4:	//top&botton&left&right
					if(!this._table) this._table = this._layer.paper.rect(this._position.x-this._width/2,this._position.y-this._height/2,this._width,this._height).attr({
						"stroke-width" : 1,
						stroke : "#333",
						fill : "#fff",
						opacity : 0.8,
						transform : "...R"+this._angle
					}).addtoLevel(this._nameLevel,this._layer);
				break;
			}

			this._isCreated = true;
		}else{
			for(var i=0; i<this._seats.length; i++){
				this._seats[i].drawing();
			}
			this._labelShape.show();
			this._table.show();
		}
	}

	p.remove = function(){
		this._labelShape.hide();
		this._table.hide();
		this._seats.forEach(function(seat){
			seat.remove();
		});
	};

	//private

	p._addPositionSeats = function(pPoints){
		var thisRow = this;
		this._posiSeats = pPoints;
		this._posiSeats.forEach(function(pSeatPt){
			pSeatPt.bindToParent(thisRow);
		});
	};

	// static
	TableRect.makeInstancefromJSON = function(pJsonObj,pEntireBBox,pLayer){
		var pts = [],categSeats = [];
		pJsonObj.seats.forEach(function(el){
			pts.push( new seatMap.Point(el.x+pEntireBBox.x,el.y+pEntireBBox.y) );
		});
		var Inst = new seatMap.TableRect(pts,pLayer);
		Inst.setKind(pJsonObj.kind);
		Inst.setPosition(new seatMap.Point(pJsonObj.posi.x+pEntireBBox.x,pJsonObj.posi.y+pEntireBBox.y));
		Inst.setCount(pJsonObj.count);
		Inst.setWidth(pJsonObj.width);
		Inst.setHeight(pJsonObj.height);
		Inst.setLabel(pJsonObj.label);
		Inst.setAngle(pJsonObj.angle);
		Inst.setId(pJsonObj.id);
		Inst.setPropSeats(pJsonObj.seats);
		return Inst;
	};

	seatMap.TableRect = seatMap.promote(TableRect, "DisplayingObject");

}());

(function(){
	function TableCircle(pPoints,pLayer){
		this.DisplayingObject_constructor();

		//public
		this.type = "tableCircle";

		//private
		this._parent = null;
		this._layer = pLayer;
		this._position = null;
		this._seats = [];
		this._posiSeats = pPoints;

		this._groupShapes = null;
		this._nameLevel = 'Seats';

		this._NumChairs = 6;
		this._openSpace = 2;
		this._radius = 24;
		this._angle = 0;
		this._prevAngle = 0;

		this._ep = 1;
		this._table = null;
		this._labelSize = 20;
		this._label = "";
		this._labelShape = null;
		this._lvlLabel = "OutlineLabels";

		this._addPositionSeats(pPoints);

		this._isCreated = false;
	}

	var p = seatMap.extend(TableCircle, seatMap.DisplayingObject);

	//public
	p.setParent = function(pParent){
		this._parent = pParent;
	};
	p.getParent = function(){
		return this._parent;
	}

	p.setPropSeats = function(pSeats){
		this._propSeats = pSeats;
	}

	p.getCount = function(){ return this._NumChairs; };
	p.setCount = function(pCount){ this._NumChairs = pCount; };

	p.getOpenSpace = function(){ return this._openSpace; };
	p.setOpenSpace = function(pSpace){ this._openSpace = pSpace; };

	p.getRadius = function(){ return this._radius; };
	p.setRadius = function(pRadius){ this._radius = pRadius; };

	p.setPosition = function(pPosi){
		this._position = pPosi;
		this._position.bindToParent(this);
	};

	p.getLayer = function(){
		return this._layer;
	}

	p.getAngle = function(){
		return this._angle;
	};


	p.drawing = function(){
		if(!this._isCreated){
			for(var i=0; i<this._propSeats.length; i++){
				var seat = new seatMap.Seat(this._posiSeats[i],this);
				seat.setAngle(this._angle+this._prevAngle);
				seat.setLabel(this._propSeats[i].label ? this._propSeats[i].label : "-");
				seat.setId(this._propSeats[i].id);
				seat.setCategory(this._propSeats[i].category);
				seat.setReserved(this._propSeats[i].reserved);
				seat.drawing();

				this._seats.push(seat);
			}

			this._labelShape = this._layer.paper.text(this._position.x,this._position.y,this._label).attr({
				"font-size" : this._labelSize,
				"transform" : "...R"+this._angle
			}).addtoLevel(this._lvlLabel,this._layer);

			this._table = this._layer.paper.circle(this._position.x,this._position.y,this._radius).attr({
				"stroke-width" : 1,
				stroke : "#333",
				fill : "#fff",
				opacity : 0.8,
				transform : "...R"+this._angle
			}).addtoLevel(this._nameLevel,this._layer);
			this._isCreated = true;
		}else{
			for(var i=0; i<this._seats.length; i++){
				this._seats[i].drawing();
			}
			this._labelShape.show();
			this._table.show();
		}
	}

	p.remove = function(){
		this._labelShape.hide();
		this._table.hide();
		this._seats.forEach(function(seat){
			seat.remove();
		});
	};

	//private

	p._addPositionSeats = function(pPoints){
		var thisRow = this;
		this._posiSeats = pPoints;
		this._posiSeats.forEach(function(pSeatPt){
			pSeatPt.bindToParent(thisRow);
		});
	};

	// static
	TableCircle.makeInstancefromJSON = function(pJsonObj,pEntireBBox,pLayer){
		var pts = [],categSeats = [];
		pJsonObj.seats.forEach(function(el){
			pts.push( new seatMap.Point(el.x+pEntireBBox.x,el.y+pEntireBBox.y) );
		});
		var Inst = new seatMap.TableCircle(pts,pLayer);
		Inst.setPosition(new seatMap.Point(pJsonObj.posi.x+pEntireBBox.x,pJsonObj.posi.y+pEntireBBox.y));
		Inst.setCount(pJsonObj.count);
		Inst.setOpenSpace(pJsonObj.openSpace);
		Inst.setRadius(pJsonObj.radius);
		Inst.setLabel(pJsonObj.label);
		Inst.setAngle(pJsonObj.angle);
		Inst.setId(pJsonObj.id);
		Inst.setPropSeats(pJsonObj.seats);
		return Inst;
	};

	seatMap.TableCircle = seatMap.promote(TableCircle, "DisplayingObject");

}());

(function(){
	function Seat(pPosi,pParent){

		this.DisplayingObject_constructor();

		//public
		this.type = "seat";

		//private

		this._parent = pParent;
		this._layer = pParent.getLayer();
		this._id = -1;

		this._posi = pPosi;
		this._category = -1;
		this._angle = pParent.getAngle();
		this._strockWidth = 1;
		this._nameLevel = 'Seats';
		this._insideScale = this._parent.getParent().getScaleInside();
		this._size = this._layer.getSeatSize()/this._insideScale;

		this._shapeLabel = null;
		this._reserved = false;
		this._label = "";
		this._labelSize = 12;
		this._outlineLbShape = null;
		this._activeBound = null;
		this._lvlActiveBound = "ActiveHandler";
		this._textBound = null;
		this._lvlOutlineLabels = "OutlineLabels";
		this._lvlBound = "BoundingArea";
		this._lvlLabel = "Labelsi";

		this._ep = 2;

		this._shape = null;
		this._isSelected = false;

		this._bookingType = 0; // 0 : available, 1 : sold other, 2 : buy own, 3 : booked Other
	};

	var p = seatMap.extend(Seat, seatMap.DisplayingObject);

	// public
	p.setCategory = function(pCate){
		this._category = pCate;
	};

	p.getCategory = function(){
		return this._category;
	};

	p.setReserved = function(pRes){
		this._reserved = pRes;
	};

	p.getReserved = function(){
		return this._reserved;
	};

	p.setId = function(pId){
		this.DisplayingObject_setId(pId);
		this._layer.groupObjects.addSeat(this);
	};

	p.setBookingState = function(pKind){
		this._bookingType = pKind;
	};

	p.drawing = function (){
		var thisSeat = this,
			instCateg = this._layer.categories.getCategory(this._category),
			moving = this._layer.isMoving(),
			allzoom = this._insideScale;
		this._bookingType = this._layer.getBookingState(this._id);
		if(this._bookingType==0) thisSeat._isSelected = false;
		if(this._isShowing()){
			if(!this._shape){
				// var fillcolor = instCateg ? instCateg.color :  "white",
				var fillcolor = availableColor,
					strokecolor = this._reserved ? selectedColor : availableColor,
					strokewidth = this._reserved ? 1/allzoom : this._strockWidth/allzoom,
					opacity = 1;

				if(!this._isAvail()){
					fillcolor = unAvailableColor;
					strokecolor = unAvailableColor;
					strokewidth = this._strockWidth/allzoom;
				}else{
					// console.log('naon sih ieu', this._bookingType)
					switch(this._bookingType){
						case 1:
							fillcolor = "#fff";
							strokecolor = "black";
							strokewidth = this._strockWidth/allzoom;
							opacity = 0.2;
						break;
						case 3:
							//fillcolor = "#333";
							strokecolor = "black";
							strokewidth = this._strockWidth/allzoom;
							opacity = 0.4;
						break;
						case 2:
							//fillcolor = "#333";
							strokecolor = "blue";
							strokewidth = 1/allzoom;
						break;
						case 4:
							//fillcolor = "#333";
							strokecolor = "red";
							strokewidth = 1/allzoom;
						break;

					}
				}
				// console.log(strokecolor, 'aye', instCateg);
				this._shape = this._layer.paper.circle(this._posi.x,this._posi.y,this._size).attr({
						fill : fillcolor,
						stroke : strokecolor,
						"stroke-width" : strokewidth,
						opacity : opacity
					}).addtoLevel(this._nameLevel,this._layer);
				this._shape.over = false;

				this._shape.mouseover(function(e){
					if(thisSeat._layer.getState()=="stateBooking" && !thisSeat._layer.isMoving() && !thisSeat._layer.isMobile()){
						// console.log('kinjat');
						if(thisSeat._isAvail() && !thisSeat._otherBooking()){
							// console.log('kanjut');
							if(!thisSeat._isSelected ){
								// console.log('kunjat');
								this.over = true;
								this.prevStrok = this.attrs.stroke;
								// console.log('preet itil', this.attrs.stroke)
								this.attr({ stroke : selectedColor, fill: selectedColor});
								this.hold();
							}
							boolIndicated = true;
							this.attr({cursor : "pointer"});
						}
						if(!thisSeat._layer.isMobile()) thisSeat._showingInfoSeat(e);
					}
				}).mouseout(function(){
					if(!thisSeat._layer.isMobile()){
						if(thisSeat._layer.getState()=="stateBooking" && !thisSeat._layer.isMoving()){
							if(thisSeat._isAvail() && !thisSeat._isSelected){
								this.unhold();
								// console.log('anjing tanah', this.prevStrok);
								this.attr({ stroke : this.prevStrok, fill: this.prevStrok});
							}
							$("#PropertyPanel").hide();
							this.over = false;
						}
					}
				}).mousedown(function(ev){
					if(thisSeat._layer.getState()=="stateBooking" && !thisSeat._layer.isMoving())	ev.stopPropagation();
				}).click(function(ev){
					if(thisSeat._layer.getState()=="stateBooking" && !thisSeat._layer.isMoving() && thisSeat._isAvail() && !thisSeat._otherBooking()){
						ev.stopPropagation();
						var currSelect = thisSeat._isSelected;
						if(!currSelect){
							if(thisSeat._layer.possibleBook()){
								if(thisSeat._parent.checkPossibleBooking(thisSeat)){
									if(!this.over || thisSeat._layer.isMobile()){
										this.prevStrok = thisSeat._reserved ? selectedColor : availableColor;
										this.attr({ stroke : selectedColor, fill: selectedColor});
										this.hold();
									}else{
										this.attr({ stroke : selectedColor, fill: selectedColor});
									}
									thisSeat._isSelected = true;
									thisSeat._layer.selectedObjects.addBookedSeat(thisSeat.getId(), thisSeat);
									thisSeat._parent.getParent().updatingBookedState("add",thisSeat.getId());
								}else{
									alert("You can't select this seat because of base logic for booking.");
								}
							}else{
								alert("You selected the all seats. You can't select the seat more.");
							}
						}else{
							this.prevStrok = thisSeat._reserved ? selectedColor : availableColor;
							// console.log('prett',this.prevStrok)
							this.unhold();
							this.attr({ stroke : this.prevStrok , fill: this.prevStrok});
							thisSeat._isSelected = false;
							thisSeat._layer.selectedObjects.removeBookedSeat(thisSeat.getId(), thisSeat);
							thisSeat._parent.getParent().updatingBookedState("remove",thisSeat.getId());
						}
						this.over = false;
					}
				});
				if(thisSeat._bookingType!=0){
					thisSeat._isSelected = true;
				}
				if(thisSeat._isSelected) this._shape.hold();
			}else{
			}
		}else{
			if(!!this._shape){
				this._layer.levels.remove(this._shape,this._nameLevel);
				this._shape = null;
			}
		}
	};
	p.remove = function(){
		if(!!this._shape){
			this._layer.levels.remove(this._shape,this._nameLevel);
			this._shape = null;
		}
	};

	p.release = function(){
		this._isSelected = false;
	};

	// private
	p._isAvail = function(){
		if((!this._layer.isManger() && this._reserved) || this._category<=0){
			return false;
		}
		return true;
	}

	p._otherBooking = function(){
		return this._bookingType==1 || this._bookingType==3 || this._bookingType==2;
	};

	p._isShowing = function(){
		var flagShowing = false;
		var viewArea = this._layer.getViewBox();
		if(viewArea[0]<this._posi.x && viewArea[0]+viewArea[2]>this._posi.x && viewArea[1]<this._posi.y && viewArea[1]+viewArea[3]>this._posi.y){
			flagShowing = true;
		}
		return flagShowing;
	};
	p._showingInfoSeat = function (pEvent){

		/* var ct = diagram.selectingComponents.getCtSelectedSeats(),strHTML="";
		var strHTML = "";

		if(category != null && thisSeat.isAvail())
			strHTML += "<h4>"+this._layer.getCategory(this._category).label+"</h4>";

		if(ct>0){
			if(ct>1)
				strHTML += "<h4>"+ct+" seats_selected"+"</h4>";
			else
				strHTML += "<h4>"+ct+" seat_selected"+"</h4>";
		}


		$("#PropertyPanel div div div p:last-child").html(strHTML); */

		$("#zonelabel").html(" <h4>" + this._parent.getParent().getLabel() + "</h4><h4>"+'zone'+"</h4>");
		$("#rowlabel").html(" <h4>" + this._parent.getLabel() + "</h4><h4>"+'row'+"</h4>");
		$("#collabel").html(" <h4>" + this._label + "</h4><h4>"+'seat'+"</h4>");

		var seatDescript = "";
		switch(this._bookingType){
			case 0: case 2:
				console.log(this._category, this._layer.categories.getCategory(this._category));
				var price = this._category > 0 ? this._layer.categories.getCategory(this._category).price : 0;
				seatDescript = 'Price : ' + this._layer._baseData.currency + ' ' + ThousandSeparator(price)
			break;
			case 1:
				seatDescript = "Excuse me, this seat is already sold."
			break;
			case 3:
				seatDescript = "Excuse me, this seat is already held."
			break;
		}

		$("#PropertyPanel div div div p:first").html("<h4>"+seatDescript+"</h4>");

		$("#PropertyPanel").show();

		var offsetX, offsetY;

		if(pEvent!=null){
			offsetY = pEvent.clientY+20;
			offsetX = ($(window).width()-pEvent.clientX)>$("#PropertyPanel").outerWidth()?pEvent.clientX:(pEvent.clientX-$("#PropertyPanel").outerWidth());
			offsetX = offsetX+20;
		}
		else{
			offsetX = ($(window).width()-$("#PropertyPanel").outerWidth())/2;
			offsetY = type=="click" ? ($(window).height()-$("#PropertyPanel").outerHeight())/2 : 50;
		}
		$("#PropertyPanel").css({"left":$(window).scrollLeft()+offsetX,"top":$(window).scrollTop()+offsetY});
	}

	seatMap.Seat = seatMap.promote(Seat, "DisplayingObject");

}());



(function(){
	"use strict";
	function GroupObjects(pLayer){
		this._layer = pLayer;
		this._childs = {
			section : [],
			row : [],
			textShape : [],
			rectShape : [],
			ellipseShape : [],
			tableRect : [],
			tableCircle : [],
			focalpoint :[]
		};
		this._seats = [];
		this._indexArray = [];
	};
	var p = GroupObjects.prototype;

	//public
	p.initialize = function(){
		this._childs = {
			section : [],
			row : [],
			textShape : [],
			rectShape : [],
			ellipseShape : [],
			tableRect : [],
			tableCircle : [],
			focalpoint :[]
		};
	}
	p.addObject = function(pObj){
		var thisGroup = this;
		this._childs[pObj.type].push(pObj);
		if(pObj.type=="section"){
			var childs = pObj.getChilds(),
				idSection = pObj.getId();
			for(var kind in childs){
				switch(kind){
					case "row": case "tableCircle": case "tableRect":
						childs[kind].forEach(function(obj){
							var idGroup = obj.id;
							obj.seats.forEach(function(seat){
								thisGroup._indexArray[seat.id]={SI:idSection, GI:idGroup};
							});
						});
					break;
				}
			}
		}
	};
	p.getAllEachObjects = function(){
		var allEachObj = [],
			thisGroup = this;
		for(var kind in this._childs){
			allEachObj = allEachObj.concat(thisGroup._childs[kind]);
		}
		return allEachObj;
	};
	p.getSections = function(){
		return this._childs.section;
	};
	p.addSeat = function(pSeat){
		this._seats[pSeat.getId()] = pSeat;
		pSeat.setBookingState(this._layer.getBookingState(pSeat.getId()));
	}
	p.getCategorySeat = function(pSeatID){
		return this._seats[pSeatID].getCategory();
	}
	/* p.updateBoughtSeatState = function(pSeats){
		for(var i=0; i<pSeats.length; i++){
			this._seats[pSeats[i]].setBookingState(4);
		}
	} */
	p.getIndexArray = function(){
		return this._indexArray;
	};
	//private

	//static

	seatMap.GroupObjects = GroupObjects;
}());

(function(){
	"use strict"
	function SelectedObjects(pLayer){
		//public

		//private
		this._layer = pLayer;
		this._nameLevel = "BoundingArea";

		this._section = null;
		this._seats = [];
		this._bookedSeats = [];
		this._bookedStands = {};
		this._CtSoldSeats = 0;
		this._CtSoldSeatsLabel = [];
		this._price = 0;


	};
	var p = SelectedObjects.prototype;

	//public
	p.setSection = function(pSect){
		this._section = pSect;
		this._layer.setState("stateBooking");

		this._CtSoldSeats = 0;
		this._CtSoldSeatsLabel = [];
		this._price = 0;
	};
	p.releaseSection= function(){
		this._section = null;
	};
	/* p.addSeat = function(pSeat){
		this._seats.push(pSeat);
	}; */

	p.getSelectedSection = function(){
		return this._section;
	};

	p.setBookedSeatOwn = function(pSeats){
		this._bookedSeats = pSeats;
		var groupObjects = this._layer.groupObjects,
			indexArray = groupObjects.getIndexArray(),
			sections = groupObjects.getSections();
		pSeats.forEach(function(pID){
			if(indexArray[pID] != undefined){
				var SI = indexArray[pID].SI;
				for(var i=0; i<sections.length; i++){
					if(sections[i].getId()==SI){
						sections[i].updatingBookedState("add");
					}
				}
			}
		});
	};

	p.addBookedStandSeats = function(pSeats){
		var bookjson = this._section.setHoldingSeats(pSeats);
		this._bookedStands[bookjson.key] = bookjson.value;
		return bookjson;

	};
	p.addBookedSeat = function(pID, pSeat){
		this._bookedSeats.push(pID);
		this._layer.updateBookedData(this._bookedSeats);
		var seatLabel = '' + pSeat._parent._label + pSeat._label;
		this._CtSoldSeatsLabel.push(seatLabel);
		window.parent.$("#selectedQty").val(this._CtSoldSeatsLabel.sort(collator.compare).join(','));
		this._CtSoldSeatsLabel.sort(collator.compare)
		var category = this._layer.groupObjects.getCategorySeat(pID);
		this._price += category>0 ? this._layer.categories.getCategory(category).price : 0;
		window.parent.$("#priceBook").val(this._layer._baseData.currency + ' ' + ThousandSeparator(this._price));
		window.parent.$('#qty_' + pSeat._category).val(this._CtSoldSeats + this._bookedSeats.length);
		var bookJson = JSON.stringify(this._layer.getBookedSeats());
		window.parent.$('#seatmapJSON').val(bookJson);
		this._layer.onSeatsUpdate(this._layer.getBookedSeats())

		this.setParentPriceType();
	}

	p.setParentPriceType = function() {
		var json = this._layer.getBookedSeats().bookedJSON;
		if (!$.isEmptyObject(json)) {
			if (window.parent.$('.container-seatmap-price-type').is(':hidden')) {
				var firstSeats = json[Object.keys(json)[0]];
				var firstCategory = Object.keys(firstSeats)[0];
				if (firstCategory !== undefined) {
					var priceType = window.parent.$('.div-price-type-' + firstCategory);
					if (priceType.length > 0 && priceType.children().html() != '') {
						priceType.children().clone().appendTo(window.parent.$('.div-seatmap-price-type'));
						window.parent.$('.container-seatmap-price-type').show();
					}
				}
			}
		} else {
			window.parent.$('.container-seatmap-price-type').hide();
			window.parent.$('.div-seatmap-price-type').html('');
		}
	}

	p.removeBookedSeat = function(pID, pSeat){
		var index = this._bookedSeats.indexOf(pID);
		if(index!=-1){
			this._bookedSeats.splice(index,1);
			this._layer.updateBookedData(this._bookedSeats);
			var seatLabel = '' + pSeat._parent._label + pSeat._label;
			var index = this._CtSoldSeatsLabel.indexOf(seatLabel);
			if(index > -1) this._CtSoldSeatsLabel.splice(index, 1);
			window.parent.$("#selectedQty").val(this._CtSoldSeatsLabel.sort(collator.compare).join(','));
			var category = this._layer.groupObjects.getCategorySeat(pID);
			this._price -= category>0 ? this._layer.categories.getCategory(category).price : 0;
			window.parent.$("#priceBook").val(this._layer._baseData.currency + ' ' + ThousandSeparator(this._price));
			window.parent.$('#qty_' + pSeat._category).val(this._CtSoldSeats + this._bookedSeats.length);
			var bookJson = JSON.stringify(this._layer.getBookedSeats());
			window.parent.$('#seatmapJSON').val(bookJson);
			this._layer.onSeatsUpdate(this._layer.getBookedSeats())
			this.setParentPriceType();
		}
	}
	p.removeBookedStandSeats = function(pCt, pData){
		this._bookedStands = {};
		this._section.releaseBookedStandSeats();
	}
	p.releaseSelectedSeats = function(){
		var thisSelect = this;
		this._bookedSeats = [];
		this._bookedStands = {};

		this._section.releaseSelectedSeats();
	};

	p.getCtBookedSeats = function(){
		//return this._CtSoldSeats + this._bookedSeats.length;
		return this._section.getCtBookedSeats();
	};

	p.getSoldCount = function(){
		return this._CtSoldSeats;
	};

	p.getBookedSeats = function(){
		var bookedJSON = {}, infoSeats=[],
			groupObjects = this._layer.groupObjects,
			thisSelect = this,
			arrIndex = groupObjects.getIndexArray(),
			sections = groupObjects.getSections();

		this._bookedSeats.forEach(function(pId){
			var indexParent = arrIndex[pId],
				flagCheck = false;
			for(var i=0; i<sections.length; i++){
				if(sections[i].getId()==indexParent.SI){
					var childs = sections[i].getChilds();
					for(var kind in childs){
						switch(kind){
							case "row": case "tableCircle": case "tableRect":
								childs[kind].forEach(function(obj){
									if(obj.id==indexParent.GI){
										obj.seats.forEach(function(seat){
											if(seat.id == pId){
												infoSeats[pId]={
													zone : sections[i].getLabel(),
													row : obj.label,
													label : seat.label,
													category : seat.category,
													price : seat.category>0 ? thisSelect._layer.categories.getCategory(seat.category).price : 0
												};
												if(!bookedJSON.hasOwnProperty(indexParent.SI))bookedJSON[indexParent.SI] = {};
												if(!bookedJSON[indexParent.SI].hasOwnProperty(seat.category)) bookedJSON[indexParent.SI][seat.category] = {seat : []};

												bookedJSON[indexParent.SI][seat.category].seat.push(seat.id);

												flagCheck = true;
											}
										});
									}
								});
							break;
						}
						if(flagCheck) break;
					}
					if(flagCheck) break;
				}
			}
		});
		var flagStand = false;
		for( var zoneID in this._bookedStands ){
			bookedJSON[zoneID] = this._bookedStands[zoneID];
			flagStand = true;
			for(var i=0; i<sections.length; i++){
				if(sections[i].getId()==Number(zoneID)){
					var cate = sections[i].getCatetoryStandSeats();
					infoSeats[zoneID]={
						zone : sections[i].getLabel(),
						category : cate.label,
						price : Number(cate.key)>0 ? cate.price : 0
					};
					break;
				}
			}
		}

		return {bookedJSON : bookedJSON, infoSeats : infoSeats, flagStand : flagStand};
	};

	p.boughtSeats = function(){
		//this._layer.groupObjects.updateBoughtSeatState(this._bookedSeats);
		if(this._bookedSeats.length>0){
			this._layer.updateBoughtData(this._bookedSeats);
			this._CtSoldSeats += this._bookedSeats.length
			this._bookedSeats = [];
			this._layer.updateBookedData(this._bookedSeats);
			this._section.boughtSeats();
		}else if(this._bookedStands!={}){
			this._bookedStands = {};
			this._section.boughtSeats();
		}
	};
	seatMap.SelectedObjects = SelectedObjects;
}());




Array.prototype.MergeContainerasPoint = function(pPoints) {
    return Array.prototype.concat.apply([], this.map(pPoints))
};

/*========= extend Raphael ============*/
Raphael.el.Scale = function(pLayer){
	pLayer.zooming(this);
	return this;
}
Raphael.el.ScaleWithOriginSize = function(pLayer){
	this.fixedSize = true;
	pLayer.zooming(this);
	return this;
}
Raphael.el.ScaleWithRotating = function(pLayer,pAngle){
	this.Angle = pAngle;
	pLayer.zooming(this);
	return this;
}
Raphael.el.setCompletedAttr = function(pCompleted, pOption) {
    if (pCompleted) {
        this.attr(pOption)
    }
    return this
};
Raphael.el.addtoLevel = function(pNameLayer, pLayer) {
    pLayer.levels.insert(this, pNameLayer);
    return this
};
Raphael.el.selected = function() {
    var val = 1.1;
    var PrivColor = [this.attrs.fill, this.attrs.stroke];
    this.privColorState = this.privColorState || [PrivColor[0], PrivColor[1]];
    PrivColor[0] = Raphael.rgb2hsb(Raphael.getRGB(PrivColor[0]).hex);
    PrivColor[1] = Raphael.rgb2hsb(Raphael.getRGB(PrivColor[1]).hex);
    PrivColor[0].s = Math.min(PrivColor[0].s * val, 0.7);
    PrivColor[0].b = PrivColor[0].b / val;
    PrivColor[1].s = Math.min(PrivColor[1].s * val, 1);
    PrivColor[1].b = PrivColor[1].b / val;
    this.attr({
        fill: "hsb(" + [PrivColor[0].h, PrivColor[0].s, PrivColor[0].b] + ")",
        stroke: "hsb(" + [PrivColor[1].h, PrivColor[1].s, PrivColor[1].b] + ")"
    });
    return this
};
Raphael.el.released = function() {
    if (this.privColorState) {
        this.attr({
            fill: this.privColorState[0],
            stroke: this.privColorState[1]
        });
        delete this.privColorState
    }
    return this
};

Raphael.el.hold = function() {
    var val = 3.5;
    var PrivColor = [this.attrs.fill, this.attrs.stroke];
		this.prevStrokeWidth = this.attrs['stroke-width'];
    this.privColorState = this.privColorState || [PrivColor[0], PrivColor[1]];
    PrivColor[0] = Raphael.rgb2hsb(Raphael.getRGB(PrivColor[0]).hex);
		PrivColor[1] = Raphael.rgb2hsb(Raphael.getRGB(PrivColor[1]).hex);
    //PrivColor[1] = Raphael.rgb2hsb(Raphael.getRGB("blue").hex);
    PrivColor[0].s = Math.min(PrivColor[0].s * val, 0.7);
    PrivColor[0].b = PrivColor[0].b / val;
    PrivColor[1].s = Math.min(PrivColor[1].s * val, 1);
    PrivColor[1].b = PrivColor[1].b / val;
    this.attr({
        fill: "hsb(" + [PrivColor[0].h, PrivColor[0].s, PrivColor[0].b] + ")",
        stroke: "hsb(" + [PrivColor[1].h, PrivColor[1].s, PrivColor[1].b] + ")",
		"stroke-width" : this.prevStrokeWidth * 2
    });
    return this
};
Raphael.el.unhold = function() {
    if (this.privColorState) {
		this.prevStrokeWidth = this.attrs['stroke-width'];
        this.attr({
            fill: this.privColorState[0],
            stroke: this.privColorState[1],
			"stroke-width" : this.prevStrokeWidth/2
        });
        delete this.privColorState
    }
    return this
};


Raphael.st.addtoLevel = function(pNameLayer,pLayer){
	pLayer.levels.insert(this, pNameLayer);
    return this
}


$.fn.isFullView= function() {
    var dom = this.get(0);
    var width = $(window).width();
    var height = $(window).height();
    if (typeof dom.getBoundingClientRect === "function") {
        var rect = dom.getBoundingClientRect();
        var booltop = rect.top >= 0 && rect.top < height;
        var boolbottom = rect.bottom >= 0 && rect.bottom <= height;
        var boolleft = rect.left >= 0 && rect.left < width;
        var boolright = rect.right >= 0 && rect.right <= width;
        return booltop && boolbottom && boolleft && boolright;
    } else {
        var PosiScrollTop = $(window).scrollTop();
        var Positop = PosiScrollTop + height;
        var PosiScrollleft = $(window).scrollLeft();
        var Posileft = PosiScrollleft + width;
        var offtop = this.offset().top;
        var viewtop = offtop + this.height();
        var offleft = this.offset().left;
        var viewleft = offleft + this.width();
        return viewtop <= Positop && offtop >= PosiScrollTop && viewleft <= Posileft && offleft >= PosiScrollleft;
    }
}

// if (typeof module !== 'undefined') {
// 	// module.exports = KTLayoutSearch;
// }
//
// export default seatMap
// var maxWidth = maxHeight = 800;
// var bookingChart, ChartJSON="";
//
// $(function(){
// 	var init = function(){
// 		bookingChart = new seatMap.ChartLayer("bookingLayer");
// 	}
//
//
// 	$(document).ready(function(){
// 		$("#load").on("click", function(){
// 			$(this).hide();
// 			window.parent.$("#book").hide();
//
// 			var str = localStorage.getItem("saveJSON");
// 			bookingChart.openfromJSON(str);
// 			var bookedData = JSON.parse(localStorage.getItem("bookedOwn")) || [],
// 				ids = [];
// 			for(var id in bookedData){
// 				ids.push(Number(id));
// 			}
// 			var bookedJSON = {
// 				bookedOwn : ids,
// 				bookedOther : [89,90,91],
// 				soldOther : [103,104,105]
// 			};
//
// 			bookingChart.setBookedState(bookedJSON);
//
// 		});
//
// 		window.parent.$("#backThumb").on('click', function(){
// 			if(confirm('If you quit from this section, your chosen seats will be released. Are you sure?')){
// 				bookingChart.releaseSelectedSeats();
// 				bookingChart.setState('stateThumb');
// 			}
// 		});
//
// 		//$('#bookQty').on('keyup change', function(){
// 		$('#bookQty', window.parent.document).on('keyup change', function(){
// 			//var qtyInput = Number($('#bookQty').val()) || 0,
// 			var qtyInput = Number($('#bookQty', window.parent.document).val()) || 0,
// 				priceSeat = bookingChart.getPriceStand(qtyInput),
// 				//likeQty = $("#likeQty").text()=="no limit" ? 100000000000:Number($("#likeQty").text()),
// 				textQty = $('#likeQty', window.parent.document).text(),
// 				likeQty = textQty=="no limit" ? 100000000000:Number(textQty),
// 				qty = bookingChart.getCurrAvailStandSeat();
//
// 			if(qty<qtyInput){
// 				if(confirm("You can book the " + qty + " seats because of available number of stand seats in this stand area. Are you gonna book the " + qty + " seats surely ?")){
// 					qtyInput = qty;
// 				}else{
// 					qtyInput = 0;
// 					//$("#priceBook").text("0 $");
// 					$('#priceBook', window.parent.document).text("0 $");
// 					//$("#book").hide();
// 					$('#book', window.parent.document).hide();
// 					//$("#backThumb").show();
// 					$('#backThumb', window.parent.document).show();
// 				}
// 				//$('#bookQty').val(qtyInput);
// 				$('#bookQty', window.parent.document).val(qtyInput);
// 			}
//
// 			if(bookingChart._baseData.limitQuantity && likeQty<qtyInput){
// 				if(confirm("You can book the " + likeQty + " seats because of your setting. Are you gonna book the " + likeQty + " seats surely ?")){
// 					qtyInput = likeQty;
// 				}else{
// 					qtyInput = 0;
// 					//$("#priceBook").text("0 $");
// 					$('#priceBook', window.parent.document).text("0 $");
// 					//$("#book").hide();
// 					$('#book', window.parent.document).hide();
// 					//$("#backThumb").show();
// 					$('#backThumb', window.parent.document).show();
// 				}
// 				//$('#bookQty').val(qtyInput);
// 				$('#bookQty', window.parent.document).val(qtyInput);
// 			}
// 			if(qtyInput>0){
// 				//$("#book").show();
// 				$('#book', window.parent.document).show();
// 				//$("#priceBook").text(priceSeat + " $");
// 				$('#priceBook', window.parent.document).text(priceSeat + " $");
// 			}else{
// 				//$("#book").hide();
// 				$('#book', window.parent.document).hide();
// 			}
// 			bookingChart.updateBookedStandSeats(qtyInput);
// 		});
// 	});
//
// 	init();
// })




