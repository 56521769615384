import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class HomeService {
  private searchQuery: string;
  private searchQuerySubject: BehaviorSubject<string>;
  public searchQuery$: Observable<string>;

  constructor() {
    this.searchQuerySubject = new BehaviorSubject<string>('');
    this.searchQuery$ = this.searchQuerySubject.asObservable();
  }

  updateQuery(query: string) {
    this.searchQuery = query;
    this.searchQuerySubject.next(query);
  }

  getQuery() {
    return this.searchQuery;
  }
}
